import { TextField, Container, Stack, Button } from "@mui/material"
import * as React from 'react';
import { useEffect, useState } from "react"
import axios from "axios"
import { Helmet } from "react-helmet-async"
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MenuItem, IconButton, InputLabel, FormControl, Select, Grid, Typography, Autocomplete } from '@mui/material';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NotificationPage() {
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    tournament: "",
    user: "",
    type: ""
  })

  const [allTournaments, setallTournaments] = useState([])
  const [allUsers, setallUsers] = useState([])


  useEffect(() => {
    getAllTournaments()
    getAllUsers()
    setFormData({ ...formData, type: 'all' })
  }, [])

  const getAllTournaments = async () => {
    const { data } = await axios.get("/api/v1/notification/get-all-tournaments")
    setallTournaments(data.data);
  }


  const getAllUsers = async () => {
    const { data } = await axios.get("/api/v1/notification/get-all-users")
    setallUsers(data.data);
  }


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post("/api/v1/notification/", formData);

      // Check for success response
      if (data && data.code === 200) {
        toast.success("Notification created successfully");
        setFormData({
          title: "",
          text: "",
          type: formData.type,
          user: "",
          tournament: ""
        });
      } else {
        // Handle other responses that may not be a success but returned data
        toast.error(data.message || "Failed to create notification");
      }
    } catch (error) {
      // Handle error response from the API
      if (error.response && error.response.data) {
        // Display the error message from the API response
        toast.error(error.response.data.message || "An error occurred");
      } else {
        // Handle unexpected errors
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 0 ? setFormData({ ...formData, type: 'all' }) : newValue === 1 ? setFormData({ ...formData, type: 'tournament' }) : newValue === 2 && setFormData({ ...formData, type: 'specificUser' })
  };

  const handleSelectChange = (e) => {
    let { name, value } = e.target;
    debugger
    setFormData({ ...formData, [name]: value })

  }
  console.log(formData);

  return <>
    <Helmet>
      <title> Feed | Trooper </title>
    </Helmet>

    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Tournament" {...a11yProps(1)} />
          <Tab label="Specific User" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Container>
          <Stack spacing={3}>
            <TextField name="title" label="Title" value={formData.title} onChange={onChange} />
            <TextField name="text" label="Text" value={formData.text} onChange={onChange} />
            <Button onClick={onSubmit}>Send</Button>
          </Stack>
        </Container>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Container>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <Autocomplete
                options={allTournaments || []} // The list of options (allUsers in this case)
                getOptionLabel={(option) => {
                  console.log(option)
                  return option?.title
                }
                } // The display value for each option
                value={
                  allTournaments.find(tournament => tournament._id === formData.tournament) || null
                } // The current value selected
                onChange={(event, newValue) => handleSelectChange({ target: { name: 'tournament', value: newValue?._id } })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Tournament" // The label for the Autocomplete input
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <TextField name="title" label="Title" value={formData.title} onChange={onChange} />
            <TextField name="text" label="Text" value={formData.text} onChange={onChange} />
            <Button onClick={onSubmit}>Send</Button>
          </Stack>
        </Container>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Container>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <Autocomplete
                options={allUsers || []} // The list of options (allUsers in this case)
                getOptionLabel={(option) => option?.name} // The display value for each option
                value={
                  allUsers.find(user => user._id === formData.user) || null
                } // The current value selected
                onChange={(event, newValue) => handleSelectChange({ target: { name: 'user', value: newValue?._id } })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User" // The label for the Autocomplete input
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <TextField name="title" label="Title" value={formData.title} onChange={onChange} />
            <TextField name="text" label="Text" value={formData.text} onChange={onChange} />
            <Button onClick={onSubmit}>Send</Button>
          </Stack>
        </Container>
      </CustomTabPanel>
    </Box>

  </>
} 