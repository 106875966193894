import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import Iconify from '../components/iconify';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';
import { useAppContext } from '../contexts/AppContext';
import { getGameList } from '../services/Common/game.service';
import useApi from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import palette from '../theme/palette';
import { toast } from 'react-toastify';

// ------------------------------------------------------------------------

export default function GamePage() {    const { setLoading } = useAppContext();
    const navigate = useNavigate()


    const columns = [
        {
            field: 'landscape_img',
            headerName: 'Image',
            minWidth: 270,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong >
                    <span >{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) =>
                <img alt={params.value} className="w-full h-full p-1" src={params.value}
                />,

        },

        {
            field: 'title',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'game_url',
            headerName: 'Link',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    {/* <Button
                        onClick={async () => {
                            if (setLoading) {
                                setLoading(true)
                            }
                            try {
                                // await deleteCategory(params.id)
                                setResult(prevGames => prevGames.filter(game => game._id !== params.id));
                                toast('Category Deleted', { type: "success" })
                            } catch (error) {
                                toast('Some Error Occured', { type: "error" })
                            }
                            finally {
                                if (setLoading) {
                                    setLoading(false);
                                }
                            }
                        }}>
                        <RiDeleteBinLine color='red' size={20} />
                    </Button> */}
                    <Button onClick={() => { navigate(`${Page.GAME_UPDATE}${params.id}`) }}>
                        <BiEdit color='black' size={20} />
                    </Button>
                </>
            ),
        },
    ];

    function getRowId(row) {
        return row._id; // Return the value of the `_id` property as the unique identifier
    }

    const { result, setResult } = useApi(() => getGameList(), { onLoading: (status) => setLoading(status) });
    // let result = res.data
    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 5,
    });

    return (
        <>
            <Helmet>
                <title> Game | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Games
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => { navigate(Page.GAME_CREATE) }}
                        >
                            New Game
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: '80vh', width: '100%' }}>
                    {result && (result.length > 0 ? <DataGrid
                        rows={result}
                        rowCount={result.length ?? 0}
                        columns={columns}
                        pageSizeOptions={[5, 10, 50]}
                        paginationModel={filter}
                        onPaginationModelChange={setFilter}
                        getRowId={getRowId}
                        rowHeight={150}
                    /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>)}
                </div>
            </Container>
        </>
    );
}
