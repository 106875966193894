import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts/AppContext';
import { getComment, updateComment } from '../../services/Common/comment.service';
import { getPostList } from '../../services/Common/post.service';
import { getUserList } from '../../services/Common/user.service';
import { MenuSearch } from '../../components/MenuSearch/MenuSearch';


export default function CommentForm({ edit }) {
    const [usersOptions, setUsersOptions] = useState([])
    const [postsOptions, setPostOptions] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [postSearch, setPostSearch] = useState('')
    const { setLoading } = useAppContext();

    const navigate = useNavigate();
    const { id } = useParams();

    const validationSchema = yup.object({
        user: yup.string().required('User is required'),
        post: yup.string().required('Post is required'),
        content: yup.string().required('Content is required')
    });

    const formik = useFormik({
        initialValues: {
            user: '',
            post: '',
            content: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            const data = {
                user: values.user,
                post: values.post,
                content: values.content
            }
            console.log(data)
            if (setLoading) {
                setLoading(true);
            }
            try {
                await updateComment(id, data)
                toast('Comment Updated Successfully', { type: 'success' })
                navigate(Page.COMMENT);

            } catch (error) {
                toast('Some Error occured', { type: 'error' })
            }
            finally {
                if (setLoading) {
                    setLoading(false);
                }
            }
        },
    });

    useEffect(() => {
        if (id) {
            const CommentDetail = async () => {
                const res = await getComment(id);
                formik.setValues(res.data[0]);
            };
            const userApi = async () => {
                try {
                    const data = await getUserList();
                    const parseUser = data.map((user) => ({ value: user._id, label: user.name }));
                    setUsersOptions(parseUser);
                } catch (error) {
                    toast('Unable to load Categories', { type: 'error' });
                }
            };
            const userPost = async () => {
                try {
                    const data = await getPostList();
                    const parsePost = data.map((post) => ({ value: post._id, label: post.title }));
                    setPostOptions(parsePost);
                } catch (error) {
                    toast('Unable to load Categories', { type: 'error' });
                }
            };

            userPost()
            userApi();
            CommentDetail();
        }
    }, [id]);

    useEffect(() => {
        const SearchFunction = async () => {

            if (userSearch === '') {
                const data = await getUserList();
                const parseUser = data.map((user) => ({ value: user._id, label: user.name }));
                setUsersOptions(parseUser);
            }
            else {
                const data = await getUserList()
                const filterUser = data.filter((user) => {
                    return user.name.toLowerCase().includes(userSearch.toLowerCase())
                })
                const parseUser = filterUser.map((user) => ({ value: user._id, label: user.name }));
                setUsersOptions(parseUser)
            }
        }
        SearchFunction()
    }, [userSearch])

    useEffect(() => {
        const SearchFunction = async () => {

            if (postSearch === '') {
                const data = await getPostList();
                const parsePost = data.map((post) => ({ value: post._id, label: post.title }));
                setPostOptions(parsePost);
            }
            else {
                const data = await getPostList()
                const filterPost = data.filter((post) => {
                    return post.title.toLowerCase().includes(postSearch.toLowerCase())
                })
                const parsePost = filterPost.map((post) => ({ value: post._id, label: post.title }));
                setPostOptions(parsePost)
            }
        }
        SearchFunction()
    }, [postSearch])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>

                    <TextField
                        sx={{ maxHeight: '50vh' }}
                        label="User"
                        variant="filled"
                        size="small"
                        name="user"
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.user && Boolean(formik.errors.user)}
                        helperText={formik.touched.user && formik.errors.user}
                        required
                        select
                        disabled
                    >

                        <MenuSearch searchQuery={userSearch} setSearchQuery={setUserSearch} />

                        {usersOptions.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        sx={{ maxHeight: '50vh' }}
                        label="Post"
                        variant="filled"
                        size="small"
                        name="post"
                        value={formik.values.post}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.post && Boolean(formik.errors.post)}
                        helperText={formik.touched.post && formik.errors.post}
                        required
                        select
                        disabled
                    >

                        <MenuSearch searchQuery={postSearch} setSearchQuery={setPostSearch} />

                        {postsOptions.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        name="content"
                        label="Content"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.content}
                        error={formik.touched.content && Boolean(formik.errors.content)}
                        helperText={formik.touched.content && formik.errors.content}
                        variant="filled"
                        size="small"
                        required
                    />

                    <Stack direction="row" gap={2}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            Submit
                        </LoadingButton>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                navigate(Page.COMMENT);
                            }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Stack>

            </form>
        </>
    );
}
