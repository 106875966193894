import axios from "axios";
import Endpoints from "../Endpoints";
import { toast } from "react-toastify";

export const getBannerList = async () => {
    const { data } = await axios.get(Endpoints.Banner.GET_BANNER_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data.data;
};

export const getBanner = async (id) => {
    const { data } = await axios.get(Endpoints.Banner.GET_BANNER + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const createBanner = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Banner.CREATE_BANNER, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updateBanner = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Banner.UPDATE_BANNER + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const deleteBanner = async (id) => {
    try {
        await axios.delete("/api/v1/banner/delete/" + id)
        toast.success("Banner deleted successfully")
    } catch (err) {
        toast.error("Some error occured")
    }
}