import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Avatar, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import Iconify from '../components/iconify';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';

// ------------------------------------------------------------------------

export default function AdminPage() {

    const results = [
        {
            id: "1",
            name: "ABCD",
            role: "Active"
        }
    ]

    const columns = [
        {
            field: 'id',
            headerName: 'Admin Id',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'role',
            headerName: 'Role',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Button>
                        <RiDeleteBinLine color='red' size={20} />
                    </Button>
                    <Button>
                        <BiEdit color='black' size={20} />
                    </Button>
                </>
            ),
        },
    ];

    const getCellStyle = (params) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    });

    return (
        <>
            <Helmet>
                <title> Admin | Trooper </title>
            </Helmet>

            <Container >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Admins
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            New Admin
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGrid rows={results} columns={columns} />
                </div>
            </Container>
        </>
    );
}
