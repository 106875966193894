import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TeamForm from '../sections/team_action/TeamForm';
// sections

// ------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    width: '60vw',
    minWidth: 320,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4, 0),
}));

// ---------------------------------------------------------------------

export default function TeamAction() {
    const { id } = useParams();
    const isEditMode = !!id;

    return (
        <>
            <Helmet>
                <title> {!isEditMode ? 'Create' : 'Edit'} Team | Trooper</title>
            </Helmet>

            <Container>
                <Typography variant="h4" gutterBottom>
                    {!isEditMode ? 'Create' : 'Edit'} Team
                </Typography>

                <StyledContent>
                    <TeamForm edit={isEditMode} />
                </StyledContent>
            </Container>
        </>
    );
}
