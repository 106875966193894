import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react"
import { toast } from 'react-toastify';
// @mui
import { Container, Stack, TextField, Typography, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from "axios"
// sections

// ------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ---------------------------------------------------------------------

export default function CreateFeedPage() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    image: '',
    link: ''
  })
  const isEditMode = !!id;

  const handleChange = (e) => {
    if (e.target.name === 'image')
      return setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const fetchLiveFeed = async () => {
      const { data } = await axios.get("/api/v1/livefeed/" + id)
      if (data) {
        setFormData({
          title: data.data.title,
          link: data.data.link
        })
      }
    }
    if (isEditMode) {
      fetchLiveFeed()
    }
  }, [])

  const onSubmit = async () => {
    const payload = new FormData()
    payload.append('title', formData.title)
    payload.append('image', formData.image)
    payload.append('link', formData.link)

    if (isEditMode) {
      const res = await axios.put("/api/v1/livefeed/" + id, payload)
      toast.success("Live Feed Updated Successfully")
    }
    else {
      const res = await axios.post("/api/v1/livefeed", payload)
      toast.success("Live Feed Created Successfully")
    }
  }

  return (
    <>
      <Helmet>
        <title> Feed | Trooper</title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          {isEditMode ? "Edit" : "Create"} Feed
        </Typography>

        <StyledContent>
          <Stack sx={{ width: "500px" }} spacing={3}>
            <TextField name='title' placeholder="Title" value={formData.title} onChange={handleChange} />
            <TextField name='image' type="file" onChange={handleChange} />
            <TextField name="link" placeholder="Link" value={formData.link} onChange={handleChange} />
            <ButtonGroup>
              <Button onClick={onSubmit}>{isEditMode ? "Edit" : "Create"}</Button>
              <Button color="secondary" onClick={() => window.history.back()}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}
