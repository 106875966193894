import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    isAuthenticated: false,
    loading: false,
    error: null,
  },
  reducers: {
    getUserStart(state, action) {
      state.loading = true;
    },
    getUserSuccess(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.loading = false
      state.error = null;
    },
    getUserFail(state, action) {
      // state.error will not be storing the whole error, but only the message.
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logOut(state, action) {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      state.loading = false;
      state.error = null;
    }
  }
})

export default authSlice.reducer;
export const authActions = authSlice.actions;