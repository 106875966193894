import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"
import { toast } from 'react-toastify';

import { Container, Stack, TextField, Typography, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from "axios"
import Page from '../data/Page'

const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export default function CreateOfferPage() {
  const { id } = useParams();
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    image: '',
    coinAmount: "",
    url: ''
  })
  const isEditMode = !!id;

  const handleChange = (e) => {
    if (e.target.name === 'image')
      return setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const fetchOffer = async () => {
      const { data } = await axios.get("/api/v1/offer/" + id)
      if (data) {
        setFormData({
          title: data.offer.title,
          coinAmount: data.offer.coinAmount,
          url: data.offer.url
        })
      }
    }

    if (isEditMode)
      fetchOffer()

  }, [])

  const onSubmit = async () => {
    const payload = new FormData()
    payload.append('title', formData.title)
    payload.append('image', formData.image)
    payload.append('url', formData.url)
    payload.append("coinAmount", formData.coinAmount)

    if (isEditMode) {
      await axios.put("/api/v1/offer/" + id, payload)
      toast.success("Offer Updated Successfully")
    }
    else {
      await axios.post("/api/v1/offer", payload)
      toast.success("Offer  Created Successfully")
    }
    navigate(Page.OFFERS)
  }

  return (
    <>
      <Helmet>
        <title> Offer | Trooper</title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          {isEditMode ? "Edit" : "Create"} Offer
        </Typography>
        <Button onClick={() => navigate(Page.OFFERS)}>Go Back</Button>

        <StyledContent>
          <Stack sx={{ width: "500px" }} spacing={3}>
            <TextField name='title' placeholder="Title" value={formData.title} onChange={handleChange} />
            <TextField name='image' type="file" onChange={handleChange} />
            <TextField name="url" placeholder="Url" value={formData.url} onChange={handleChange} />
            <TextField name="coinAmount" placeholder="Coin Amount" value={formData.coinAmount} onChange={handleChange} type={"number"} />
            <ButtonGroup>
              <Button onClick={onSubmit}>{isEditMode ? "Edit" : "Create"}</Button>
              <Button color="secondary" onClick={() => navigate(Page.OFFERS)}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}
