import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button, Box, ButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { getPost, updatePost, createPost } from '../../services/Common/post.service';
import { getUserList } from '../../services/Common/user.service'
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts/AppContext';
import { MenuSearch } from '../../components/MenuSearch/MenuSearch';
import ViewComment from '../../pages/ViewComment';


export default function PostForm({ edit }) {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: ""
    })

    const { title, description, image } = formData

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const getPost = async () => {
            const post = await getPost(id)
            setFormData({
                ...formData,
                title: post.title,
                description: post.description,
            })
        }
    }, [])

    const handleChange = (e) => {
        if (e.target.name === "image") {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] })
            return
        }
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        const data = new FormData()
        data.append('title', title)
        data.append('description', description)
        data.append('image', image)
        if (edit) {
            const res = await updatePost(id, data)
            toast.success("Post updated successfully")
            navigate("/post")
            return
        }
        const res = await createPost(data)
        toast.success("Post created successfully")
        navigate("/post")
    }

    return (
        <Stack spacing={3}>
            <TextField
                name="title"
                value={title}
                onChange={handleChange}
                label="Title"
            />
            <TextField
                name="description"
                value={description}
                onChange={handleChange}
                label="Description"
            />
            <TextField
                name="image"
                type="file"
                onChange={handleChange}
                label="Image"
            />
            <ButtonGroup>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                >Submit</Button>
                <Button
                    variant="outlined"
                    color='secondary'
                    onClick={() => navigate("/post")}
                >Go Back</Button>
            </ButtonGroup>
        </Stack>
    );
}
