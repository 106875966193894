import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, MenuItem, Stack, TextField, Button, IconButton, InputLabel, FormControl, Select, Grid, Box, Typography, ButtonGroup, Autocomplete } from '@mui/material';
import { createTournament, getTournament, updateTournament } from '../services/Common/tournament.service';
import { getUserList } from "../services/Common/user.service"
import { getTeamList } from "../services/Common/team.service"
import { toast } from 'react-toastify';
import { AiFillDelete } from 'react-icons/ai';
import { nanoid } from "nanoid"
import axios from "axios"
// components
import { DataGrid } from '@mui/x-data-grid';
// sections


const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

const teamStatuses = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Accepted" },
  { value: 2, label: "Lost" },
  { value: 3, label: "Removed" }
]

export default function ManageTournamentTeams() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [users, setUsers] = useState([])
  const [selectedUserToInvite, setSelectedUserToInvite] = useState("")
  const [tournament, setTournament] = useState({ teams: [], })
  const autoCompleteRef = useRef()


  const handleInvite = async () => {
    const res = await axios.post('/api/v1/tournament/create-tournament-invitation', {
      user_id: selectedUserToInvite,
      tournament_id: id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
      }
    })
    if (res) {
      toast.success("Invitation sent")
    }
  }

  const declareTeamAsLost = async (team_id) => {
    const res = await axios.put('/api/v1/tournament/declare-team-lost', {
      team_id: team_id,
      tournament_id: id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
      }
    })
    if (res) {
      toast.success("Team Removed")
      // remove from the state also
      setTournament({
        ...tournament,
        teams: tournament.teams.map(team => team._id !== team_id ? team : {
          ...team,
          status: 2
        })
      })
    }
  }

  const removeTeam = async (team_id) => {
    const res = await axios.put('/api/v1/tournament/ban-team', {
      team_id: team_id,
      tournament_id: id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
      }
    })
    if (res) {
      toast.success("Team Removed")
      // remove from the state also
      setTournament({
        ...tournament,
        teams: tournament.teams.map(team => team._id !== team_id ? team : {
          ...team,
          status: 3
        })
      })
    }
  }




  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUserList();
      if (response)
        setUsers(response);

    }
    fetchUsers();
  }, [id])

  const columns = [
    {
      field: '_id',
      headerName: 'Team Id',
      minWidth: 250,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'team_name',
      headerName: 'Team Name',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params) => (
        <>{
          // Show the status of the team
          teamStatuses.map(status => {
            if (status.value === params.row.status) {
              return status.label
            }
          }
          )
        }</>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <strong >
          <span >{params.colDef.headerName}</span>
        </strong>
      ),
      renderCell: (params) => (<>
        <Stack spacing={2}>
          <Button variant="outlined" color="secondary" onClick={() => declareTeamAsLost(params.row._id)}>
            Declare Lost
          </Button>
          <Button color="secondary" onClick={() => removeTeam(params.row._id)}>
            Remove Team
          </Button>
        </Stack>
      </>
      )
    },
  ]

  useEffect(() => {
    const fetchTournament = async () => {
      const { data } = await getTournament(id);
      if (data) setTournament(data);
    }
    fetchTournament();
  }, [id])



  return (
    <>
      <Helmet>
        <title> Manage Tournament | Trooper</title>
      </Helmet>
      <Container>

        <StyledContent>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h4" sx={{ mb: 2 }}>Manage Tournament</Typography>
            <Button color="info" onClick={() => navigate(`/tournament`)}>
              Go Back
            </Button>
          </Stack>
          <Stack spacing={3}>
            <Typography variant="h6" gutterBottom>
              Teams
            </Typography>
            <DataGrid
              rows={tournament?.teams.map((team) => ({ ...team, id: team._id }))}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              rowHeight={150}
            />
            {/* <FormControl fullWidth>
              <InputLabel id="invite_user_select">Invite Users</InputLabel>
              <Select labelId="invite_user_select" onChange={(e) => {
                setSelectedUserToInvite(e.target.value)
              }
              }>
                {
                  users.map(user => {
                    return (
                      <MenuItem value={user._id}>{user.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl> */}
            <Autocomplete
              ref={autoCompleteRef}
              disablePortal
              id="combo-box-demo"
              options={users}
              sx={{ width: 300 }}
              autoHighlight
              getOptionLabel={(option) => option.email + " " + option.name}
              renderInput={(params) => <TextField {...params} label="User" />}
              onChange={(e, value) => {
                if (!value) return

                console.log(value)
                setSelectedUserToInvite(value._id)

                // // only add the product if it's not there
                // if (formData.products.find(p => p.product._id === product.product._id)) return
                // setFormData((f) => ({
                //   ...f,
                //   products: [...f.products, { ...product, quantity: 1 }],
                // }))

                autoCompleteRef.current.value = ""
                console.log(selectedUserToInvite)
              }}
            />
            {
              selectedUserToInvite && <Button onClick={handleInvite
              }>Invite</Button>
            }
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}


