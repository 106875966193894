import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import {
    Box,
    Button,
    Container,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../components/iconify";
import axios from "axios";

import Page from "../data/Page";
import { useNavigate } from "react-router-dom";
import { getTournamentList } from "../services/Common/tournament.service";
import { useAppContext } from "../contexts/AppContext";
import ProtectedRoute from "../components/ProtectedRoute";
import useApi from "../hooks/useApi";
import palette from "../theme/palette";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

export default function TournamentPage() {
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const role = useSelector(state => state.auth?.user?.role[0])
    const [tournaments, setTournaments] = useState([]);
    const [loading, setLoading] = useState(true);

    const rows = getTournamentRows({ navigate });

    useEffect(() => {
        if (isAuthenticated === false) return
        if (!role) return

        const loadTournaments = async () => {
            setLoading(true);
            try {
                let endpoint = `/api/v1/tournament`;
                if (role === "organization")
                    endpoint = "/api/v1/tournament?organizerOnly=true";


                const { data } = await axios.get(endpoint);
                setTournaments(data.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
                console.log(err.message);
                toast(err.message);
            }
        };


        loadTournaments(role)
    }, [isAuthenticated, role]);

    if (loading) return <Loading />;
    return (
        <ProtectedRoute>
            <Helmet>
                <title> Tournament | Trooper </title>
            </Helmet>

            <Container>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                    >
                        Tournaments
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder="Search..."
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => {
                                navigate(Page.TOURNAMENT_CREATE);
                            }}
                        >
                            New Tournament
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: "80vh", width: "100%" }}>
                    {tournaments.length > 0 ? (
                        <DataGrid
                            rows={tournaments}
                            rowCount={tournaments.length ?? 0}
                            columns={rows}
                            pageSizeOptions={[5, 10, 50]}
                            getRowId={(row) => row._id}
                            rowHeight={150}
                        />
                    ) : (
                        <Box
                            sx={{
                                textAlign: "center",
                                color: palette.grey[600],
                                fontSize: "18px",
                            }}
                        >
                            No Row Available
                        </Box>
                    )}
                </div>
            </Container>
        </ProtectedRoute>
    );
}

function getTournamentRows({ navigate }) {
    return [
        {
            field: "landscape_img",
            headerName: "Image",
            minWidth: 270,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <img
                    className="w-full h-full p-1"
                    src={params.value}
                    alt=""
                />
            ),
        },

        {
            field: "title",
            headerName: "Name",
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: "team_type",
            headerName: "Type",
            minWidth: 50,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: "team_count",
            headerName: "Max Teams",
            minWidth: 30,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: "time_remaining",
            headerName: "Time Remaining",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => {
                const registrationClosedOn = new Date(
                    params.row.registration_closed_on
                );
                const currentTime = new Date();
                const remainingTime = Math.abs(
                    Math.floor((registrationClosedOn - currentTime) / (1000 * 60))
                );
                return <>{remainingTime.toString()} mins</>;
            },
        },
        {
            headerName: "Action",
            field: "actions",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Stack spacing={2}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate(`${Page.TOURNAMENT_UPDATE}${params.id}`);
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => navigate(`/tournament/manage/${params.id}`)}
                        >
                            Manage
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate(`/tournament/${params.id}/teams`)}
                        >
                            Teams
                        </Button>
                    </Stack>
                </>
            ),
        },
    ];
}
