import axios from "axios";
import Endpoints from "../Endpoints";

export const getCategoryList = async () => {
    const { data } = await axios.get(Endpoints.Category.GET_CATEGORY_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data.data;
};

export const getCategory = async (id) => {
    const { data } = await axios.get(Endpoints.Category.GET_CATEGORY + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const createCategory = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Category.CREATE_CATEGORY, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updateCategory = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Category.UPDATE_CATEGORY + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};