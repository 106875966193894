import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
// sections
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { getTeamList } from '../services/Common/team.service';
import { useAppContext } from '../contexts/AppContext';
import { useState } from 'react';
import palette from '../theme/palette';
import { toast } from 'react-toastify';

// ------------------------------------ ------------------------------------

export default function TeamPage() {
    const { setLoading } = useAppContext();

    const columns = [
        {
            field: '_id',
            headerName: 'Team Id',
            minWidth: 250,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'image',
            headerName: 'Image',
            minWidth: 270,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong >
                    <span >{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <img alt={params.value} className='w-full h-full p-1' src={params.value} />
            )
        },
        {
            field: 'team_name',
            headerName: 'Team Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Button
                        onClick={async () => {
                            if (setLoading) {
                                setLoading(true)
                            }
                            try {
                                // await deleteCategory(params.id)
                                setResult(prevTeams => prevTeams.filter(team => team._id !== params.id));
                                toast('Category Deleted', { type: "success" })
                            } catch (error) {
                                toast('Some Error Occured', { type: "error" })
                            }
                            finally {
                                if (setLoading) {
                                    setLoading(false);
                                }
                            }
                        }}>
                        <RiDeleteBinLine color='red' size={20} />
                    </Button>
                </>
            ),
        },
    ];

    function getRowId(row) {
        return row._id; // Return the value of the `_id` property as the unique identifier
    }

    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 5,
    });

    const { result, setResult } = useApi(() => getTeamList(), { onLoading: (status) => setLoading(status) });

    return (
        <>
            <Helmet>
                <title> Team | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Teams
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                    </div>
                </Stack>

                <div style={{ height: '80vh', minWidth: '100%' }}>
                    {result && (result.length > 0 ?
                        <DataGrid
                            rows={result}
                            rowCount={result.length ?? 0}
                            columns={columns}
                            pageSizeOptions={[5, 10, 50]}
                            paginationModel={filter}
                            onPaginationModelChange={setFilter}
                            getRowId={getRowId}
                            rowHeight={150}

                        /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>)}
                </div>
            </Container>
        </>
    );
}
