import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
// @mui
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { IconButton, InputAdornment } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// components
import Iconify from '../components/iconify/Iconify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { getAuthToken } from '../services/Auth/Auth';
import { login } from "../store/actions/auth";
import Page from '../data/Page';
import { useAppContext } from '../contexts/AppContext';
import { useSelector } from 'react-redux';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Trooper Live
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function SignIn() {
  const navigate = useNavigate()
  const { appState, setAppState } = useAppContext();
  const [showPassword, setShowPassword] = React.useState(false)
  const { isAuthenticated } = useSelector(state => state.auth)

  const validationSchema = yup.object({
    email: yup.string().required().min(3).label('Email'),
    password: yup.string().required().label('Password'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      await login(values.email, values.password).catch(() => {
        toast('Unable to login. Please try again.', { type: 'error' });
        formik.setFieldError('email', 'Email is incorrect');
        formik.setFieldError('password', 'Password is incorrect');
      })
    }
  })

  useEffect(() => {
    if (isAuthenticated) navigate("/")
  }, [isAuthenticated]);


  return (
    <>

      <Helmet>
        <title> Login | Troopers </title>
      </Helmet >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main', height: '70px', width: '70px' }}>
          </Avatar>
          <Typography gutterBottom sx={{ margin: '2rem 0' }} variant="h4">
            Sign in to Troopers
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Email Address"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
              fullWidth
              required
            />
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              margin="normal"
              fullWidth
              required
            />

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={!formik.isValid}
              loading={formik.isSubmitting}
              size='large'
              sx={{ mt: 3 }}
            >
              Login
            </LoadingButton>

          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />

      </Container>
      {/* {showToaster && (
        <CustomizedToaster
          openStatus={true}
          Message={"This is a success message!"}
          status={"success"}
          setShowToaster={setShowToaster}
        />
      )} */}

    </>



  );
}