import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import Iconify from '../components/iconify';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { useAppContext } from '../contexts/AppContext';
import { deleteCategory, getCategoryList } from '../services/Common/category.service';
import { useState } from 'react';
import palette from '../theme/palette';
import { toast } from 'react-toastify';

// ------------------------------------------------------------------------

export default function CategoryPage() {
    const navigate = useNavigate()
    const { setLoading } = useAppContext();

    const columns = [
        {
            field: '_id',
            headerName: 'Category Id',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },

        {
            field: 'background_img',
            headerName: 'Image',
            minWidth: 270,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong >
                    <span >{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <img
                    className='w-full h-full p-1'
                    src={params.value}
                    alt=""
                />
            )

        },        
        {
            field: 'game_title',
            headerName: 'Game Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>               
                    <Button onClick={() => { navigate(`${Page.CATEGORTY_UPDATE}${params.id}`) }}>
                        <BiEdit color='black' size={20} />
                    </Button>
                </>
            ),
        },
    ];

    function getRowId(row) {
        return row._id; // Return the value of the `_id` property as the unique identifier
    }

    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 5,
    });

    const { result, setResult } = useApi(() => getCategoryList(), { onLoading: (status) => setLoading(status) });
    // let result = res.data !== null ? res.data : [];
    return (
        <>
            <Helmet>
                <title> Category | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Categorys
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => { navigate(Page.CATEGORTY_CREATE) }}
                        >
                            New Category
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: '80vh', minWidth: '100%' }}>
                    {result && (result.length > 0 ?
                        <DataGrid
                            rows={result}
                            rowCount={result.length ?? 0}
                            columns={columns}
                            pageSizeOptions={[5, 10, 50]}
                            paginationModel={filter}
                            onPaginationModelChange={setFilter}
                            getRowId={getRowId}
                            rowHeight={150}

                        /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>)}
                </div>
            </Container>
        </>
    );
}
