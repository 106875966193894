import { TextField } from "@mui/material"

export const MenuSearch = ({ categorySearch, setCategorySearch }) => {

    return (
        <TextField
            sx={{ padding: 1, width: '100%' }}
            placeholder="Search"
            name="categorySearch"
            value={categorySearch}
            onChange={e => setCategorySearch(e.target.value)}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}

        >
        </TextField>
    )
}