import { useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import axios from "axios"
import { loadPersistantUser } from "./store/actions/auth"
// layouts
import DashboardLayout from './layouts/dashboard'
import SimpleLayout from './layouts/simple';

// pages
import DashboardAppPage from './pages/DashboardAppPage'
import UserPage from "./pages/UserPage";
import TournamentPage from "./pages/TournamentPage";
import GamePage from "./pages/GamePage";
import TransactionPage from "./pages/TransactionPage";
import StreamPage from "./pages/StreamPage";
import AdminPage from "./pages/AdminPage";
import UserAction from "./pages/UserAction";
import PalyerAction from "./pages/PlayerAction";
import TeamAction from "./pages/TeamAction";
import GameAction from "./pages/GameAction";

import CategoryAction from "./pages/CategoryAction";
import TournamentAction from "./pages/TournamentAction";
import SignIn from "./pages/SignIn";
import Page404 from './pages/Page404';
import CategoryPage from "./pages/CategoryPage";
import Page from "./data/Page";

import { getAuthToken } from "./services/Auth/Auth";
import TeamPage from "./pages/TeamPage";
import PlayerPage from "./pages/PlayerPage";
import PostPage from "./pages/PostPage";
import BannerPage from "./pages/BannerPage";
import CommentPage from "./pages/CommentPage";
import BannerAction from "./pages/BannerAction";
import PostAction from "./pages/PostAction";
import CommentAction from "./pages/CommentAction";
import ManageTournament from "./pages/ManageTournament";
import FeedPage from "./pages/LiveFeed";
import CreateFeedPage from "./pages/CreateFeedPage";
import ManageStage from "./pages/ManageStage"
import ManageTournamentTeams from "./pages/ManageTournamentTeams";
import SectionPage from "./pages/SectionPage";
import CreateSectionPage from "./pages/CreateSectionPage";
import DashboardPage from "./pages/DashboardAppPage"
import WithdrawalPage from "./pages/WithdrawalPage";
import NotificationPage from "./pages/NotificationPage"
import ManageRound from "./pages/ManageRound";
import OffersPage from "./pages/OffersPage"
import CreateOfferPage from "./pages/CreateOfferPage"

export default function Router() {
    const navigate = useNavigate();

    useEffect(() => {
        loadPersistantUser().catch(() => {
            navigate(Page.LOGIN);
        })
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to='/dashboard' />, index: true },
                { path: '/dashboard', element: <DashboardPage /> },
                { path: '/user', element: <UserPage /> },
                { path: '/tournament', element: <TournamentPage /> },
                { path: '/game', element: <GamePage /> },
                { path: '/team', element: <TeamPage /> },
                { path: '/player', element: <PlayerPage /> },
                { path: '/feed', element: <FeedPage /> },
                { path: '/feed/create', element: <CreateFeedPage /> },
                { path: "/feed/create/:id", element: <CreateFeedPage /> },
                { path: '/transaction', element: <TransactionPage /> },
                { path: '/stream', element: <StreamPage /> },
                { path: '/admin', element: <AdminPage /> },
                { path: '/category', element: <CategoryPage /> },
                { path: '/post', element: <PostPage /> },
                { path: '/comment', element: <CommentPage /> },
                { path: '/banner', element: <BannerPage /> },
                { path: '/user/update/:id', element: <UserAction /> },
                { path: '/tournament/create', element: <TournamentAction /> },
                { path: '/tournament/update/:id', element: <TournamentAction /> },
                { path: "/tournament/manage/:id", element: <ManageTournament /> },
                { path: '/category/create', element: <CategoryAction /> },
                { path: '/category/update/:id', element: <CategoryAction /> },
                { path: '/team/create', element: <TeamAction /> },
                { path: '/team/update/:id', element: <TeamAction /> },
                { path: "/rounds/:id", element: <ManageRound /> },
                { path: '/game/create', element: <GameAction /> },
                { path: '/game/update/:id', element: <GameAction /> },
                { path: '/player/create', element: <PalyerAction /> },
                { path: '/player/update/:id', element: <PalyerAction /> },
                { path: '/banner/create', element: <BannerAction /> },
                { path: '/banner/update/:id', element: <BannerAction /> },
                { path: '/post/create', element: <PostAction /> },
                { path: '/post/update/:id', element: <PostAction /> },
                { path: '/comment/create', element: <CommentAction /> },
                { path: '/comment/update/:id', element: <CommentAction /> },
                { path: "/stage/:id", element: <ManageStage /> },
                { path: "/tournament/:id/teams", element: <ManageTournamentTeams /> },
                { path: '/section', element: <SectionPage /> },
                { path: '/section/create', element: <CreateSectionPage /> },
                { path: '/withdrawal', element: <WithdrawalPage /> },
                { path: "/notification", element: <NotificationPage /> },
                { path: "/offers", element: <OffersPage /> },
                { path: "/offer/create", element: <CreateOfferPage /> },
                { path: "/offer/update/:id", element: <CreateOfferPage /> }
            ] 
        },
        {
            path: "/login",
            element: <SignIn />
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/" />, index: true },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
    ])
    return routes
}