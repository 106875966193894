import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayerForm } from '../sections/player_action';
// sections

// ------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    width: '60vw',
    minWidth: 320,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4, 0),
}));

// ---------------------------------------------------------------------

export default function PlayerAction() {
    const { id } = useParams();
    const isEditMode = !!id;

    return (
        <>
            <Helmet>
                <title> {!isEditMode ? 'Create' : 'Edit'} Player | Trooper</title>
            </Helmet>

            <Container>
                <Typography variant="h4" gutterBottom>
                    {!isEditMode ? 'Create' : 'Edit'} Player
                </Typography>

                <StyledContent>
                    <PlayerForm edit={isEditMode} />
                </StyledContent>
            </Container>
        </>
    );
}
