import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import Iconify from '../components/iconify';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';

// ------------------------------------------------------------------------

export default function TransactionPage() {

    const results = [
        {
            id: "1",
            transaction_id: "12",
            user_id: "123",
            name: "yash",
            amount: "123",
            status: "Completed",
            time: "12:12"
        }
    ]

    const columns = [
        {
            field: 'transaction_id',
            headerName: 'Transaction Id',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'user_id',
            headerName: 'User Id',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'name',
            headerName: 'Username',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'time',
            headerName: 'Time',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Button>
                        <RiDeleteBinLine color='red' size={20} />
                    </Button>
                    <Button>
                        <BiEdit color='black' size={20} />
                    </Button>
                </>
            ),
        },
    ];


    return (
        <>
            <Helmet>
                <title> Transaction | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Transactions
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            New Transaction
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: '70vh', minWidth: '100%' }}>
                    <DataGrid rows={results} columns={columns} />
                </div>
            </Container>
        </>
    );
}
