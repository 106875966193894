import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import routes from "../routes";

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading === false && !isAuthenticated) {
      navigate(routes.auth);
    }
  }, [loading]);

  if (loading) return <Loading />;
  return <>{children}</>;
}
