import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { toast } from 'react-toastify';
import { createCategory, getCategory, updateCategory } from '../../services/Common/category.service';
import { useAppContext } from '../../contexts/AppContext';

export default function CategoryForm({ edit }) {

    const { setLoading } = useAppContext();
    const navigate = useNavigate();
    const { id } = useParams();

    const validationSchema = yup.object({
        game_title: yup.string().required('Game name is required'),
        status: yup.string().required('Status is required field'),
    });

    const formik = useFormik({
        initialValues: {
            game_title: '',
            status: '',
            background_img: null,
            logo_img: null
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData()
            formData.append('game_title', values.game_title)
            formData.append('status', values.status)
            formData.append('background_img', values.background_img)
            formData.append('logo_img', values.logo_img)
            if (setLoading) {
                setLoading(true);
            }
            try {
                const res = edit ? await updateCategory(id, formData) : await createCategory(formData);
                toast(`Category ${!edit ? 'Created' : 'Updated'}`, { type: 'success' });
                navigate(Page.CATEGORY);
            } catch (error) {
                toast('Some Error occured', { type: 'error' })
            } finally {
                if (setLoading) {
                    setLoading(false);
                }
            }
        },
    });

    useEffect(() => {
        if (id) {
            const CategoryDetail = async () => {
                const res = await getCategory(id);
                formik.setValues(res.data);
            };
            CategoryDetail();
        }
    }, [id]);



    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>

                    <TextField
                        name="game_title"
                        label="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.game_title}
                        error={formik.touched.game_title && Boolean(formik.errors.game_title)}
                        helperText={formik.touched.game_title && formik.errors.game_title}
                        variant="filled"
                        size="small"
                        required
                    />

                    <TextField
                        name="status"
                        type="text"
                        label="status"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.status}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        variant="filled"
                        size="small"
                        required
                    />

                    {/* Image field commented for now */}
                    <TextField
                        name="background_img"
                        label="Background Image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        size="small"
                        onChange={(event) => formik.setFieldValue('background_img', event.target.files[0])}
                        required={edit ? false : true}

                    />

                    <TextField
                        name="logo_img"
                        label="Logo Image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        size="small"
                        onChange={(event) => formik.setFieldValue('logo_img', event.target.files[0])}
                        required={edit ? false : true}

                    />


                    <Stack direction="row" gap={2}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            Submit
                        </LoadingButton>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                navigate(Page.CATEGORY);
                            }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Stack>

            </form>
        </>
    );
}
