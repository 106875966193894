import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"
import { toast } from 'react-toastify';
// @mui
import { Container, Stack, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from "axios"

const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export default function CreateSectionPage() {
  const { id } = useParams();
  const navigate = useNavigate()
  const [selectTournaments, setSelectTournaments] = useState([])
  const [formData, setFormData] = useState({
    title: '',
    image: '',
    tournaments: [],
  })
  const isEditMode = !!id;

  useEffect(() => {
    // get the tournamnets
    const fetchTournaments = async () => {
      const { data } = await axios.get("/api/v1/tournament")
      if (data) {
        if (data === null) return
        setSelectTournaments(data.data)
      }
      console.log(selectTournaments, "Select Tournaments")
    }
    fetchTournaments()
  }, [])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name === "image") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] })
    }
  }

  const onSubmit = async () => {

    // make new form data
    const payload = new FormData()
    payload.append("title", formData.title)
    payload.append("image", formData.image)

    const tournamentIds = formData.tournaments.map(tournament => tournament._id)

    payload.append("tournaments", tournamentIds)


    const res = await axios.post("/api/v1/section", payload)
    if (res) {
      toast.success("Section Created Successfully")
      navigate("/section")
    }
  }

  return (
    <>
      <Helmet>
        <title> Sections | Trooper</title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          Create Section
        </Typography>

        <StyledContent>
          <Stack sx={{ width: "500px" }} spacing={3}>
            <TextField name='title' placeholder="Title" value={formData.title} onChange={handleChange} />
            {/* Make a select for selecting tournaments to put into this section. */}
            <TextField name='image' placeholder="Image" type="file" onChange={handleChange} />
            <FormControl>
              <InputLabel id="select_tournaments_label">Select Tournaments</InputLabel>
              <Select onChange={(e) => {
                if (formData.tournaments.includes(e.target.value)) {
                  toast.error("Tournament already selected")
                  return
                }
                setFormData({
                  ...formData, tournaments: [
                    ...formData.tournaments,
                    e.target.value
                  ]
                })
              }}>
                {selectTournaments.map((tournament) => (
                  <MenuItem key={tournament._id} value={tournament}>{tournament.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {
              formData.tournaments.map((tournament) => (
                <Box key={tournament._id} sx={{ width: "400px", background: "#eee", borderRadius: "10px", border: "1px solid #999", height: "60px", display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                  <Typography>{tournament.title}</Typography>
                  <Button variant="contained" color="secondary"
                    onClick={
                      () => {
                        setFormData({
                          ...formData, tournaments: formData.tournaments.filter((t) => t._id !== tournament._id)
                        })
                      }
                    }
                  >Delete</Button>
                </Box>
              ))
            }

            <Button onClick={onSubmit}>Create</Button>
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}
