import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useApi = (apiFunction, options = { cache: false }) => {
  const location = useLocation();
  const cached = location.state?.cached;
  const [result, setResult] = useState(options.cache ? cached ?? null : null);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if (options.onLoading) {
        options.onLoading(true);
      }
      setIsLoading(true);
      try {
        const response = await apiFunction();
        setResult(response);
        setUserList(response)
      } catch (error) {
        console.log(error)
      } finally {
        if (options.onLoading) {
          options.onLoading(false);
        }
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (searchQuery === '') {
      setUserList(result)
    }
    else {
      const filteredList = result.filter((user) => {
        return user.name.toLowerCase().includes(searchQuery)
      })
      setUserList(filteredList)
    }
  }, [searchQuery])

  return { result, setResult, isLoading, searchQuery, setSearchQuery, userList };
};

export default useApi;
