

import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, MenuItem, Stack, TextField, Button, IconButton, InputLabel, FormControl, Select, Grid, Box, Typography, ButtonGroup } from '@mui/material';
import { toast } from 'react-toastify';
import { AiFillDelete } from 'react-icons/ai';
import axios from "axios"
// components
import { DataGrid } from '@mui/x-data-grid';
// sections


const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export default function ManageStage() {

  const { id } = useParams();
  const navigate = useNavigate();
  const [stage, setStage] = useState(null)
  const [teams, setTeams] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [rounds, setRounds] = useState(stage?.rounds || [])
  const [formData, setFormData] = useState({
    room_name: "",
    room_id: "",
    room_password: "",
    map_name: ""
  })

  const stageTeams = stage?.teams || []


  useEffect(() => {
    const fetchStage = async () => {
      try {
        const res = await axios.get(`/api/v1/stage/${id}`)
        setStage(res.data.data.stage)
      } catch (error) {
        console.log(error)
      }
    }
    fetchStage()
  }, [id, rounds])

  const createRound = async () => {
    const teamIds = teams.map(team => team._id)
    try {
      const res = await axios.post(`/api/v1/stage/create-round/${id}`, {
        room_id: formData.room_id,
        room_name: formData.room_name,
        room_password: formData.room_password,
        map_name: formData.map_name,
        teamsIds: teamIds,
      })

      setRounds([...rounds, res.data.data.round])

      toast.success("Round created successfully")
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <>
      <Helmet>
        <title> Manage Stage | Trooper</title>
      </Helmet>
      <Container>
        <StyledContent>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h4" sx={{ mb: 2 }}>Manage Stage </Typography>
            <Button color="info" onClick={() => navigate(`/tournament/manage/${stage.tournament._id}`)}>
              Go Back To Stages
            </Button>
          </Stack>
          <DataGrid
            rows={stage?.rounds || []}
            getRowId={(row) => row._id}
            columns={[
              { field: 'id', headerName: 'ID', width: 70 },
              { field: 'room_name', headerName: 'Room Name', width: 130 },
              { field: 'room_id', headerName: 'Room Id', width: 130 },
              { field: 'room_password', headerName: 'Room Password', width: 130 },
              {
                field: "teams", headerName: "Teams", width: 130, renderCell: (params) => (
                  <>
                    <Button onClick={() => navigate(`/rounds/${params.row._id}`)}>Manage</Button>
                  </>
                )
              },
              {
                field: "Delete",
                headerName: "Delete",
                width: 130,
                renderCell: (params) => (
                  <Button color="secondary" variant="contained" startIcon={<AiFillDelete />}
                    onClick={() => {
                      axios.delete(`/api/v1/stage/delete-round/${params.row._id}`)
                      setRounds(rounds.filter(round => round._id !== params.row._id))
                    }}>
                    Delete
                  </Button>
                )
              }
            ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />

          {
            showForm ? <>
              <Stack spacing={3}>
                <Typography variant="h4" sx={{ mb: 2 }}>Create new lobby/group</Typography>

                <TextField id="outlined-basic" value={formData.room_name} name="room_name" label="Room Name" variant="outlined" onChange={handleChange} />
                <TextField id="outlined-basic" value={formData.room_id} name="room_id" label="Room Id" variant="outlined" onChange={handleChange} />
                <TextField id="outlined-basic" value={formData.room_password} name="room_password" label="Room Password" variant="outlined" onChange={handleChange} />
                <TextField name="map_name" value={formData.map_name} placeholder="Map Name" onChange={handleChange} />
                {/* Create a dropdown for the teams, use the stage.teams array */}
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                  <Select onChange={(e) => {
                    // dont repeat teams
                    if (teams.includes(e.target.value)) return
                    setTeams([...teams, e.target.value])
                  }
                  }>
                    {
                      stageTeams.map(team => !team?.inLobby && <MenuItem value={team} key={team._id}>{team.team_name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                {
                  teams.map(team =>
                    <Box key={team._id} sx={{ width: "400px", background: "#eee", borderRadius: "10px", border: "1px solid #999", height: "60px", display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                      <Typography variant="h6">{team.team_name}</Typography>
                      <Button color={"secondary"} onClick={() => { setTeams(teams.filter(t => t._id !== team._id)) }}>Remove Team</Button>
                    </Box>
                  )
                }
                <ButtonGroup>
                  <Button variant="contained" onClick={createRound}>Submit</Button>
                  <Button variant="outlined" color="secondary" onClick={() => setShowForm(false)}>Cancel</Button>
                </ButtonGroup>
              </Stack>
            </> :
              <Button
                variant="outlined"
                onClick={() => setShowForm(true)}
              >Create Match For current stage.</Button>
          }
        </StyledContent >
      </Container >
    </>
  );
}
