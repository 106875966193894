import axios from "axios";
import Endpoints from "../Endpoints";


export const getGameList = async () => {
    const { data } = await axios.get(Endpoints.Games.GET_GAMES_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    // console.log(data.data)
    return data.data;
};

export const getGame = async (id) => {
    const { data } = await axios.get(Endpoints.Games.GET_GAMES + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const createGame = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Games.CREATE_GAMES, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updateGame = async (id, data) => {
    // console.log(data, id)
    const {
        data: { result },
    } = await axios.put(Endpoints.Games.UPDATE_GAMES + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`,
        }
    });
    return result;
};