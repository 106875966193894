import React from 'react'
import { Typography, Box, Grid } from "@mui/material"

export default function RoundTeamList({ teams }) {
  return (
    <>
      <Typography variant="h4">
        Teams
      </Typography>
      <Grid container>
        {teams && teams.map(team =>
          <Grid xs={12} lg={3} key={team._id} >
            <Box sx={{ padding: '1rem', backgroundColor: "#eee", marginRight: "1rem", marginBottom: "1rem" }} >
              <Typography>{team.team_name}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  )
}
