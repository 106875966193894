import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import AppWidgetSummary from '../sections/dashboard/app/AppWidgetSummary';
import React, { useEffect, useState } from 'react';
import Chart from '../components/chart/Chart'
import axios from 'axios'
import { getUserList } from '../services/Common/user.service';

const userData = [
  {
    name: "Jan",
    "Active User": 4000,
  },
  {
    name: "Feb",
    "Active User": 3000,
  },
  {
    name: "Mar",
    "Active User": 5000,
  },
  {
    name: "Apr",
    "Active User": 4000,
  },
  {
    name: "May",
    "Active User": 3000,
  },
  {
    name: "Jun",
    "Active User": 2000,
  },
  {
    name: "Jul",
    "Active User": 4000,
  },
  {
    name: "Agu",
    "Active User": 3000,
  },
  {
    name: "Sep",
    "Active User": 4000,
  },
  {
    name: "Oct",
    "Active User": 1000,
  },
  {
    name: "Nov",
    "Active User": 4000,
  },
  {
    name: "Dec",
    "Active User": 3000,
  },
];

export default function DashboardAppPage() {


  return (
    <>
      <Helmet>
        <title> Dashboard | Trooper </title>
      </Helmet>

      <Container maxWidth="xl">
        <Chart
          data={userData}
          title="User Analytics"
          grid
          dataKey="Active User"
        />
      </Container>
    </>
  );
}
