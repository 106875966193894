import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, Stack, TextField, Button, FormControl, Select, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../data/Page';
import { getUser, updateUser } from '../../services/Common/user.service';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux"


export default function UserForm({ edit }) {
    const { isAuthenticated } = useSelector(state => state.auth)
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        role: "",
        profileImage: ""
    })
    const [loading, setLoading] = useState(false)

    const { name, email, role, profileImage } = formData

    useEffect(() => {
        if (!isAuthenticated) return;
        const getUserDetails = async () => {
            try {
                const { data: user } = await getUser(id);
                console.log(user)
                setFormData({
                    name: user[0].name,
                    email: user[0].email,
                    role: user[0].role[0]
                })
            } catch (err) {
                console.log(err)
                toast("Error getting the user details. Please refresh the page. ")
            }
        };

        if (id)
            getUserDetails();

    }, [id, isAuthenticated]);

    const onChange = (e) => {
        const name = e.target.name

        if (name === 'profileImage')
            return setFormData({ ...formData, [name]: e.target.files[0] })

        setFormData({ ...formData, [name]: e.target.value })
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            const payload = new FormData();

            payload.append("name", name)
            payload.append("email", email)
            payload.append("profileImage", profileImage)
            payload.append('role', role)

            await updateUser(id, payload)
            toast("User successfully updated.")
        } catch (err) {
            console.log(err.message)
            toast("Some error occoured please try again.")
        }
        setLoading(false)
    }


    return (
        <>
            <Stack spacing={3}>
                <TextField
                    name="name"
                    label="Name"
                    variant="filled"
                    size="small"
                    value={name}
                    required
                    onChange={onChange}
                />
                <TextField
                    name="email"
                    type="email"
                    label="Email Address"
                    variant="filled"
                    size="small"
                    value={email}
                    required
                    onChange={onChange}
                />
                <FormControl>
                    <InputLabel id="account_type_select">Select User Type</InputLabel>
                    <Select labelId="account_type_select"
                        name="role"
                        value={role}
                        onChange={onChange}>
                        <MenuItem value={"user"}>Normal Account</MenuItem>
                        <MenuItem value={"organization"}>Organizer Account</MenuItem>
                        <MenuItem value={"admin"}>Admin Account</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    name="profileImage"
                    label="Upload Image"
                    type="file"
                    onChange={onChange}
                    variant="filled"
                    size="small"
                    required={edit ? false : true}
                />
                <Stack direction="row" gap={2}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        loading={loading}
                        onClick={onSubmit}
                    >
                        Submit
                    </LoadingButton>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => navigate(Page.USER)}
                    >
                        Go Back
                    </Button>
                </Stack>
            </Stack >
        </>
    );
}
