
let Page = {
    ADMIN: '/admin',
    APP: '/app',
    BANNER: '/banner',
    BANNER_CREATE: '/banner/create',
    BANNER_UPDATE: '/banner/update/',
    CATEGORY: '/category',
    CATEGORTY_CREATE: '/category/create',
    CATEGORTY_UPDATE: '/category/update/',
    COMMENT: '/comment',
    COMMENT_CREATE: '/comment/create',
    COMMENT_UPDATE: '/comment/update/',
    DASHBOARD: '/dashboard',
    FEED: '/feed',
    FEED_CREATE: '/feed/create',
    GAME: '/game',
    GAME_CREATE: '/game/create',
    GAME_UPDATE: '/game/update/',
    LOGIN: '/login',
    MANAGE_STAGE: '/stage',
    PLAYER: '/player',
    PLAYER_CREATE: '/player/create',
    PLAYER_UPDATE: '/player/update/',
    POST: '/post',
    POST_CREATE: '/post/create',
    POST_UPDATE: '/post/update/',
    SECTION: '/section/',
    SECTION_CREATE: '/section/create/',
    STREAM: '/stream',
    TEAM: '/team',
    TEAM_CREATE: '/team/create',
    TEAM_UPDATE: '/team/update/',
    TOURNAMENT: '/tournament',
    TOURNAMENT_CREATE: '/tournament/create',
    TOURNAMENT_MANAGE: '/tournament/manage',
    TOURNAMENT_UPDATE: '/tournament/update/',
    TRANSACTION: '/transaction',
    TRANSACTION_CREATE: '/transaction',
    TRANSACTION_UPDATE: '/transaction',
    USER: '/user',
    USER_UPDATE: '/user/update/',
    WITHDRAWAL: '/withdrawal',
    NOTIFICATION: "/notification",
    OFFERS: "/offers",
    OFFER_CREATE: "/offer/create"
};


export default Page;
