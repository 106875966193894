import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { toast } from 'react-toastify';
import { createPlayer, getPlayer, updatePlayer } from '../../services/Common/player.service'
import { useAppContext } from '../../contexts/AppContext';
import { getCategoryList } from '../../services/Common/category.service';
import { getUserList } from '../../services/Common/user.service';


export default function PlayerForm({ edit }) {

    const [usersOptions, setUsersOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([]);
    const { setLoading } = useAppContext();
    const navigate = useNavigate();
    const { id } = useParams();

    const validationSchema = yup.object({
    });

    const formik = useFormik({
        initialValues: {
            ingame_id: '',
            ingame_name: '',
            category_id: '',
            refId: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            const data = {
                ingame_id: values.ingame_id,
                ingame_name: values.ingame_name,
                category_id: values.category_id,
                refId: values.refId
            }
            if (setLoading) {
                setLoading(true);
            }
            try {
                edit ? await updatePlayer(id, data) : await createPlayer(data);
                toast(`Player ${!edit ? 'Created' : 'Updated'}`, { type: 'success' });
                navigate(Page.PLAYER);
            } catch (error) {
                toast('Some Error occured', { type: 'error' })
            }
            finally {
                if (setLoading) {
                    setLoading(false);
                }
            }
        },
    });

    useEffect(() => {
        if (id) {
            const PlayerDetail = async () => {
                const res = await getPlayer(id);
                formik.setValues(res.data);
            };
            const categoryApi = async () => {
                try {
                    const data = await getCategoryList();
                    const parseCategory = data.map((category) => ({ value: category._id, label: category.game_title }));
                    setCategoryOptions(parseCategory);
                } catch (error) {
                    toast('Unable to load Categories', { type: 'error' });
                }
            };

            const userApi = async () => {
                try {
                    const data = await getUserList();
                    const parseUser = data.map((user) => ({ value: user._id, label: user.name }));
                    setUsersOptions(parseUser);
                } catch (error) {
                    toast('Unable to load Categories', { type: 'error' });
                }
            };

            userApi()
            categoryApi();
            PlayerDetail();
        }
    }, [id]);


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>

                    <TextField
                        name="ingame_id"
                        label="Ingame Id"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ingame_id}
                        error={formik.touched.ingame_id && Boolean(formik.errors.ingame_id)}
                        helperText={formik.touched.ingame_id && formik.errors.ingame_id}
                        variant="filled"
                        size="small"
                        required
                    />

                    <TextField
                        name="ingame_name"
                        label="Ingame Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ingame_name}
                        error={formik.touched.ingame_name && Boolean(formik.errors.ingame_name)}
                        helperText={formik.touched.ingame_name && formik.errors.ingame_name}
                        variant="filled"
                        size="small"
                        required
                    />

                    <TextField
                        sx={{ maxHeight: '50vh' }}
                        label="Category"
                        variant="filled"
                        size="small"
                        name="category_id"
                        value={formik.values.category_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.category_id && Boolean(formik.errors.category_id)}
                        helperText={formik.touched.category_id && formik.errors.category_id}
                        required
                        select
                    >

                        {categoryOptions.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        sx={{ maxHeight: '50vh' }}
                        label="Reference Id"
                        variant="filled"
                        size="small"
                        name="refId"
                        value={formik.values.refId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.refId && Boolean(formik.errors.refId)}
                        helperText={formik.touched.refId && formik.errors.refId}
                        required
                        select
                    >

                        {usersOptions.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <Stack direction="row" gap={2}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            Submit
                        </LoadingButton>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                navigate(Page.PLAYER);
                            }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Stack>

            </form>
        </>
    );
}