import axios from "axios";
import Endpoints from "../Endpoints";
// const host = "http://ec2-3-128-197-188.us-east-2.compute.amazonaws.com";
const host = 'http://localhost:8080'

export const getPlayerList = async () => {
    const { data } = await axios.get(Endpoints.Player.GET_PLAYER_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    // console.log(data.data)
    return data.data;
};

export const getPlayer = async (id) => {
    const { data } = await axios.get(Endpoints.Player.GET_PLAYER + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}
export const createPlayer = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Player.CREATE_PLAYER, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updatePlayer = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Player.UPDATE_PLAYER + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};