import { Helmet } from 'react-helmet-async';
import { Avatar, Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'; import Page from '../data/Page';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { getUserList } from '../services/Common/user.service';
import { useAppContext } from '../contexts/AppContext';
import palette from '../theme/palette';

export default function UserPage() {
    const navigate = useNavigate()
    const { setLoading } = useAppContext();

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'profileImage',
            headerName: 'Image',
            minWidth: 100,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong >
                    <span >{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => <Avatar alt={params.value} src={params.value} />,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <Button onClick={() => { navigate(`${Page.USER_UPDATE}${params.id}`) }}>
                    <BiEdit color='black' size={20} />
                </Button>
            ),
        },
    ];

    const { userList, searchQuery, setSearchQuery } = useApi(() => getUserList(), { onLoading: (status) => setLoading(status) });
    return (
        <>
            <Helmet>
                <title> User | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Users
                    </Typography>

                    <div className="flex gap-4 items-center">
                        {/* <IconButton>
                            <Iconify icon="eva:search-fill" />
                        </IconButton> */}
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                </Stack>

                <div style={{ height: '80vh', minWidth: '100%' }}>
                    {userList && (userList.length > 0 ?
                        <DataGrid
                            rows={userList}
                            rowCount={userList.length ?? 0}
                            columns={columns}
                            pageSizeOptions={[5, 10, 50]}
                            getRowId={row => row._id}
                        /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>
                    )}
                </div>
            </Container>
        </>
    );
}
