import { createContext, useState, useContext } from 'react';

const AppContext = createContext({});

function AppProvider({ children }) {
  const defautValue = {
    user: {},
    isLoading: false,
  };
  const [appState, setAppState] = useState(defautValue);

  const setLoading = (status) => {
    setAppState({
      ...appState,
      isLoading: status,
    });
  };
  return (
    <AppContext.Provider value={{ appState, setAppState, setLoading }}>
      {children}
    </AppContext.Provider>
  );
}

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
