import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Dialog, Stack, Typography, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import palette from '../theme/palette';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from "react-redux"


export default function WithdrawalPage() {
  const { isAuthenticated } = useSelector(state => state.auth)
  const [withdrawals, setWithdrawals] = useState([])
  const [paymentDetailsModalText, setPaymentDetailsModalText] = useState("")
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false)

  const approveWithdrawal = async (withdrawalId) => {
    const { data } = await axios.put(`/api/v1/wallet/withdrawal-request/${withdrawalId}`, { status: "approved" })

    if (data) {
      const withdrawalRequest = data.data.withdrawal
      let newWithdrawals = withdrawals.map(withdrawal => withdrawal._id === withdrawalRequest._id ? withdrawalRequest : withdrawal)
      setWithdrawals(newWithdrawals)
    }
  }

  const handleClose = () =>
    setShowPaymentDetailsModal(false);

  useEffect(() => {
    const fetchWithdrawals = async () => {
      const { data } = await axios.get("/api/v1/wallet/withdrawal-request")
      if (data)
        setWithdrawals(data.data)
    }

    if (isAuthenticated)
      fetchWithdrawals()
  }, [isAuthenticated])


  const columns = [
    {
      field: 'user',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.user.name
    },
    {
      headerName: "Request Amount",
      minWidth: 20,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography>
            {params.row?.amount} {params.row?.type}
          </Typography>
        )
      }
    },
    {
      field: 'paymentDetails',
      headerName: "Payment Details",
      maxWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let disabled = false
        if (!params.row.paymentDetails) disabled = true;
        return (
          <Button disabled={disabled} onClick={() => {
            setShowPaymentDetailsModal(true)
            setPaymentDetailsModalText(params.row.paymentDetails)
          }}>
            View Payment Details
          </Button>
        )
      }
    },
    {
      field: 'wallet',
      headerName: 'Balance',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Stack spacing="1">
            <Typography>
              TRP - {params.row?.wallet?.trp}
            </Typography>
            <Typography>
              EV - {params.row?.wallet?.ev}
            </Typography>
          </Stack>
        )
      }
    },
    {
      headerName: 'Action',
      field: 'actions',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {
            params.row?.status === "approved" ?
              <Button disabled>
                Approved
              </Button> : <Button onClick={() => approveWithdrawal(params.row._id)}>
                Approve
              </Button>

          }

        </>
      ),
    },
  ];

  function getRowId(row) {
    return row._id; // Return the value of the `_id` property as the unique identifier
  }

  // let result = res.data
  const [filter, setFilter] = useState({
    page: 0,
    pageSize: 5,
  });

  return (
    <>
      <Helmet>
        <title> Sections | Trooper </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Withdrawal Requests
          </Typography>
        </Stack>
        <div style={{ height: '80vh', width: '100%' }}>
          {withdrawals &&
            (withdrawals.length > 0 ?
              <DataGrid
                rows={withdrawals}
                rowCount={withdrawals.length ?? 0}
                columns={columns}
                pageSizeOptions={[5, 10, 50]}
                paginationModel={filter}
                onPaginationModelChange={setFilter}
                getRowId={getRowId}
                rowHeight={150}
              /> :
              <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }}>
                No Row Available
              </Box>)}
          <Dialog open={showPaymentDetailsModal} onClose={handleClose} >
            <Box sx={{ background: "white", padding: "1rem" }}>
              {paymentDetailsModalText}
            </Box>
          </Dialog>
        </div>
      </Container>
    </>
  );
}
