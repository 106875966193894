import axios from "axios";
import Endpoints from "../Endpoints";
// const host = "http://ec2-3-128-197-188.us-east-2.compute.amazonaws.com";
const host = 'http://localhost:8080'

export const getTeamList = async () => {
    const { data } = await axios.get(Endpoints.Team.GET_TEAM_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    // console.log(data.data)
    return data.data;
};

export const getTeam = async (id) => {
    const { data } = await axios.get(Endpoints.Team.GET_TEAM + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const createTeam = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Team.CREATE_TEAM, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updateTeam = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Team.UPDATE_TEAM + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};