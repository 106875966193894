import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, Stack, TextField, Button, IconButton, InputLabel, FormControl, Select, Grid, Box, Typography } from '@mui/material';
import { createTournament, getTournament, updateTournament } from '../../services/Common/tournament.service';
import { getCategoryList } from '../../services/Common/category.service';
import { toast } from 'react-toastify';
import { AiFillDelete } from 'react-icons/ai';
import { nanoid } from "nanoid"
import Page from '../../data/Page';
import { useSelector } from "react-redux"

const levelOptions = [
    { label: 'Multimap', value: 'multimap' },
    { label: 'Single Map', value: 'singlemap' }
];

const tournamentStatuses = [
    { label: 'Started', value: 0 },
    { label: 'Registration Phase', value: 1 },
    { label: 'Tournament Active', value: 2 },
    { label: 'Tournament in Review', value: 3 },
    { label: 'Tournament Completed/Cancelled', value: 4 }
]

const teamOptions = [
    { label: 'Solo', value: 'solo' },
    { label: 'Duo', value: 'duo' },
    { label: 'Squad', value: 'squad' },
];

const defaultTags = ["Daily Scrims", "Small Tournaments", "Solo Tournaments", "Duo Tournaments", "Trio Tournaments", "Squad Tournaments"]

export default function TournamentForm({ edit }) {
    const navigate = useNavigate()
    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(null)
    const [categories, setCategories] = useState([])
    const [formData, setFormData] = useState({
        title: "",
        level_type: "",
        team_type: "",
        team_count: "",
        match_count: "",
        tags: [],
        registration_closed_on: "",
        category: "",
        status: 0,
        prizes: [{
            title: "",
            price: "",
            status: ""
        }],
        details: {
            rules: "",
            points_distribution: [{
                id: nanoid(10),
                title: "",
                points: ""
            }],
            updates: ""
        },
        landscape_img: null,
        portrait_img: null,
    })

    const { id } = useParams();

    useEffect(() => {
        if (edit) {
            (async () => {
                let { data } = await getTournament(id);
                setCurrentStatus(data.status)
                setFormData({
                    title: data.title,
                    level_type: data.level_type,
                    team_type: data.team_type,
                    team_count: data.team_count,
                    match_count: data.match_count,
                    registration_closed_on: data.registration_closed_on,
                    status: data.status,
                    prizes: data.prizes || [],
                    details: data.details,
                    landscape_img: data.landscape_img,
                    portrait_img: data.portrait_img,
                    category: data.category._id,
                    tags: data.tags || []
                });
            })()
        }
    }, [edit])

    useEffect(() => {
        (async () => {
            const categoryRes = await getCategoryList();
            setCategories(categoryRes)
        })()
    }, [])



    const {
        title,
        level_type,
        team_type,
        team_count,
        match_count,
        registration_closed_on,
        status,
        prizes,
        details,
        category,
        tags
    } = formData;

    const role = localStorage.getItem("role")

    const disabled = role === "admin" ? false :
        role === "organization" && currentStatus !== 4 ? false : true;

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === "portrait_img" || name === "landscape_img")
            value = e.target.files[0];

        setFormData({ ...formData, [name]: value })
    }

    const handlePrizeChange = (e, index) => {
        const fieldName = e.target.name

        const newState = {
            ...formData,
            prizes: prizes?.map((prize, i) => i === index ? { ...prize, [fieldName]: e.target.value } : prize)
        }

        setFormData(newState)
    }

    const handlePrizeDistributionChange = (e, index) => {
        const fieldName = e.target.name

        const newState = {
            ...formData,
            details: {
                ...details,
                points_distribution: details.points_distribution?.map((point, i) => i === index ? { ...point, [fieldName]: e.target.value } : point)
            }
        }

        setFormData(newState)
    }



    const handleSubmit = async () => {
        setLoading(true)
        const data = new FormData();
        try {
            // Append all data to form data
            Object.keys(formData).forEach(key => {
                if (key === "prizes") {
                    data.append(key, JSON.stringify(formData[key]))
                } else if (key === "details") {
                    data.append(key, JSON.stringify(formData[key]))
                } else {
                    data.append(key, formData[key])
                }
            })

            if (edit) {
                await updateTournament(id, data)
                toast.success("Tournament Updated Successfully")
                return navigate(Page.TOURNAMENT)
            }

            await createTournament(data)
            toast.success("Tournament Created Successfully")
            navigate(Page.TOURNAMENT)
        } catch (error) {
            toast.error(error.message)
        }
        setLoading(false)
    }

    return <Box sx={{ width: "100%" }}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Box>
                    <Stack spacing={3}>
                        <TextField
                            name="title"
                            value={title} placeholder="Enter title"
                            onChange={handleChange}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel id="level_type_select">Level Type</InputLabel>
                            <Select labelId='level_type_select' name="level_type" value={level_type} onChange={handleChange}>
                                {levelOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="team_type_select">Team Type</InputLabel>
                            <Select labelId="team_type_select" name="team_type" value={team_type} onChange={handleChange}>
                                {teamOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            name='team_count'
                            value={team_count}
                            placeholder='No of teams per tournament'
                            type="number"
                            fullWidth
                            onChange={handleChange}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="category_select">Select Category</InputLabel>
                            <Select labelId="category_select" name="category" value={category} onChange={handleChange}>
                                {categories?.map((category) =>
                                    <MenuItem key={category._id} value={category._id}>
                                        {category.game_title}
                                    </MenuItem>
                                )}

                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <Stack spacing={3}>
                        <TextField
                            name="registration_closed_on"
                            value={registration_closed_on}
                            placeholder='Enter Closing Date'
                            type='date'
                            onChange={handleChange}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="status_select">Status</InputLabel>
                            <Select labelId="status_select" name="status" value={status} onChange={handleChange}>
                                {tournamentStatuses?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            name="landscape_img"
                            label="Landscape Image"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            size="small"

                            type='file'
                            placeholder='Portrait Image'
                            onChange={handleChange}
                        />
                        <TextField
                            name="portrait_img"
                            label="Portrait Image"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            size="small"
                            type='file'
                            placeholder='Enter Landscape Portrait Image'
                            onChange={handleChange}
                        />
                    </Stack>
                </Box>
            </Grid>
            {/* Details */}
            <Grid item xs={12}>
                <Stack spacing={3}>
                    <Typography variant="h4">Tournament Details</Typography>
                    <TextField
                        value={details.rules}
                        placeholder='Enter Rules'
                        multiline
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                details: {
                                    ...details,
                                    rules: e.target.value
                                }
                            })
                        }}
                        fullWidth
                    />
                    <TextField
                        value={details.updates}
                        placeholder='Enter Updates'
                        multiline
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                details: {
                                    ...details,
                                    updates: e.target.value
                                }
                            })
                        }}
                        fullWidth
                    />
                    <Typography variant="h5">Points Distribution</Typography>
                    <Stack spacing={3}>
                        {details.points_distribution?.map((point, index) =>
                            <Stack direction="row" spacing={3} key={index}>
                                <TextField
                                    value={point.title}
                                    placeholder='Title'
                                    name="title"
                                    onChange={(e) => handlePrizeDistributionChange(e, index)}
                                />
                                <TextField
                                    value={point.points}
                                    type='number'
                                    name="points"
                                    placeholder='Points'
                                    onChange={(e) => handlePrizeDistributionChange(e, index)}
                                />
                                <IconButton
                                    onClick={() => setFormData({
                                        ...formData,
                                        details: {
                                            ...details,
                                            points_distribution: details.points_distribution.filter((_, i) => i !== index)
                                        }
                                    })}
                                >
                                    <AiFillDelete />
                                </IconButton>
                            </Stack>
                        )}
                        <Button
                            onClick={() => setFormData({
                                ...formData,
                                details: {
                                    ...details,
                                    points_distribution: [...details.points_distribution, {
                                        id: nanoid(10),
                                        title: "",
                                        points: ""
                                    }]
                                }
                            })}
                        > Add Point Distribution </Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={3}>
                    <Typography variant="h4">Prizes</Typography>
                    <Stack spacing={3}>
                        {prizes?.map((prize, index) =>
                            <Stack direction="row" spacing={3} key={index}>
                                <TextField
                                    value={prize.title}
                                    placeholder='Title'
                                    name="title"
                                    onChange={e => handlePrizeChange(e, index)}
                                />
                                <TextField
                                    value={prize.price}
                                    type='number'
                                    placeholder='Price'
                                    name="price"
                                    onChange={e => handlePrizeChange(e, index)}

                                />
                                <IconButton
                                    onClick={() => setFormData({
                                        ...formData,
                                        prizes: prizes.filter((_, i) => i !== index)
                                    })}
                                >
                                    <AiFillDelete />
                                </IconButton>
                            </Stack>
                        )}
                        <Button
                            onClick={() => setFormData({
                                ...formData,
                                prizes: [...prizes, {
                                    title: "",
                                    price: ""
                                }]
                            })}
                        > Add Prize </Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                    <Select onChange={(e) => {
                        let tag = e.target.value
                        if (tags.includes(e.target.value)) return
                        let newTags = [...tags, tag]
                        setFormData({ ...formData, tags: newTags })
                    }}>
                        {defaultTags.map(tag => <MenuItem value={tag} key={tag}>{tag}</MenuItem>)}
                    </Select>
                </FormControl>
                {
                    tags.map(tag =>
                        <Box key={tag}
                            sx={{ width: "400px", background: "#eee", height: "60px", display: "flex", justifyContent: "space-around", alignItems: 'center', marginY: "1rem" }}>
                            <Typography variant="h6">{tag}</Typography>
                            <Button color={"secondary"}
                                onClick={() => {
                                    let currentTagToBeDeleted = tag
                                    let newTags = tags.filter(tag => tag !== currentTagToBeDeleted)
                                    setFormData({ ...formData, tags: newTags })
                                }}>Remove Tag</Button>
                        </Box>
                    )
                }
            </Grid>
        </Grid>
        <br />
        <Button variant="contained" disabled={disabled} onClick={handleSubmit}>{edit ? "Edit" : "Create"} Tournament</Button>
        <Button variant="outlined" color="secondary" onClick={() => navigate(`/tournament`)
        }>Go Back</Button>
    </Box>
}
