import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from 'react-toastify';
import { useEffect } from "react";
// routes
import Router from './routes';
import { AppProvider } from "./contexts/AppContext";
// theme
import ThemeProvider from './theme';
// components
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"

import { Provider } from "react-redux"
import store from "./store/index.js"
import { loadPersistantUser } from "./store/actions/auth"

const __DEV__ = document.domain === "localhost";
function App() {
  axios.defaults.baseURL = __DEV__ ? "http://localhost:8080" : "https://api.trooper.live"

  useEffect(() => {
    loadPersistantUser()
    const token = localStorage.getItem('USER_TOKEN')

    if (token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, []);



  return (
    <HelmetProvider>
      <Provider store={store}>
        <AppProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ToastContainer />
              <Router />
            </ThemeProvider>
          </BrowserRouter>
        </AppProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;