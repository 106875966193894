import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, MenuItem, Stack, TextField, Button, IconButton, InputLabel, FormControl, Select, Grid, Box, Typography, ButtonGroup } from '@mui/material';
import { createTournament, getTournament, updateTournament } from '../services/Common/tournament.service';
import { getUserList } from "../services/Common/user.service"
import { getTeamList } from "../services/Common/team.service"
import { toast } from 'react-toastify';
import { AiFillDelete } from 'react-icons/ai';
import { nanoid } from "nanoid"
import axios from "axios"
// components
import { DataGrid } from '@mui/x-data-grid';
// sections


const StyledContent = styled('div')(({ theme }) => ({
  width: '60vw',
  minWidth: 320,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

const teamStatuses = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Accepted" },
  { value: 2, label: "Lost" },
]

export default function ManageTournament() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tournament, setTournament] = useState({ teams: [], stages: [] })
  const [selectedTeamToGivePrize, setSelectedTeamToGivePrize] = useState(null)
  const [selectedPlayerToGivePrize, setSelectedPlayerToGivePrize] = useState(null)
  const [selectedPrize, setSelectedPrize] = useState(null)
  const [prizeUserOrTeam, setPrizeUserOrTeam] = useState("user")
  const [showStageForm, setShowStageForm] = useState(false)
  const [stageFormData, setStageFormData] = useState({
    name: "",
    match_count: 2,
    group_count: ""
  })

  const { name, match_count, group_count } = stageFormData


  const handleGivePrize = async () => {
    const res = await axios.put('/api/v1/tournament/give-prize', {
      team_id: selectedTeamToGivePrize,
      user_id: selectedPlayerToGivePrize?.refId,
      tournament_id: id,
      prize_id: selectedPrize._id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
      }
    })
    if (res) {
      toast.success("Prize Given")
    }
  }


  const createStage = async () => {
    const res = await axios.post('/api/v1/stage/create', {
      tournament_id: id,
      name,
      match_count,
      group_count
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
      }
    })
    if (res) {
      toast.success("Stage Created")
      setShowStageForm(false)
      setTournament({
        ...tournament,
        stages: [...tournament.stages, res.data.data.stage]
      })
    }
  }


  useEffect(() => {
    const fetchTournament = async () => {
      const { data } = await getTournament(id);
      if (data) {
        setTournament(data);
      }
      console.log(data)
    }
    fetchTournament();
  }, [id])

  return (
    <>
      <Helmet>
        <title> Manage Tournament | Trooper</title>
      </Helmet>
      <Container>
        <StyledContent>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h4" sx={{ mb: 2 }}>Manage Tournament</Typography>
            <Button color="info" onClick={() => navigate(`/tournament`)}>
              Go Back
            </Button>
          </Stack>
          {/* Make a material ui autocomplete field that would send request to the api */}
          <Stack spacing={3}>
            {
              prizeUserOrTeam === "user" ?
                <FormControl fullWidth>
                  <InputLabel id="prize_user_select">Select User For Prize</InputLabel>
                  <Select labelId="prize_user_select" onChange={(e) => {
                    setSelectedPlayerToGivePrize(e.target.value)
                  }
                  }>
                    {
                      tournament?.players?.map(player => {
                        return (
                          <MenuItem key={player._id} value={player}>{player.ingame_name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                  <Button variant="outlined" onClick={() => setPrizeUserOrTeam("team")} > Select Team Instead </Button>
                </FormControl> :
                <FormControl fullWidth>
                  <InputLabel id="prize_team_select">Select Team For Prize</InputLabel>
                  <Select labelId="prize_team_select" onChange={(e) => {
                    setSelectedTeamToGivePrize(e.target.value)
                  }}>
                    {tournament?.teams.map(team => (
                      <MenuItem value={team._id}>{team.team_name}</MenuItem>
                    ))}
                  </Select>
                  <Button variant="outlined" onClick={() => setPrizeUserOrTeam("user")}> Select Individual Player Instead </Button>
                </FormControl>
            }
            {/* Add an select for the prize to be given.Use the prizes in the tournament  */}
            <FormControl fullWidth>
              <InputLabel id="prize_select">Select Prize</InputLabel>

              <Select labelId="prize_select" onChange={e => setSelectedPrize(e.target.value)}>
                {tournament.prizes && tournament.prizes.map(prize => prize.status !== 1 && (
                  <MenuItem value={prize}>{prize.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {
              ((selectedTeamToGivePrize || selectedPlayerToGivePrize) && selectedPrize) &&
              <Button onClick={handleGivePrize} variant="contained">Give Prize</Button>
            }
            {/* Display the teams of the tournament in a table */}
            <Typography variant="h6" gutterBottom>
              Stages
            </Typography>
            {/* Make a table for tournament stages */}
            <DataGrid
              rows={tournament?.stages}
              getRowId={row => row._id}
              columns={getStageColumns({ navigate })}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
            <Box>
              {!showStageForm && <Button variant="outlined" onClick={() => setShowStageForm(true)}>
                Create Stage
              </Button>}
              {
                showStageForm &&
                <Box sx={{ my: 2 }}>
                  <FormControl fullWidth>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        name="name"
                        placeholder="Stage Name"
                        onChange={(e) => setStageFormData({ ...stageFormData, name: e.target.value })}
                      />
                      <TextField
                        fullWidth
                        name="match_count"
                        type="number"
                        placeholder='Match Count'
                        onChange={(e) => setStageFormData({ ...stageFormData, match_count: e.target.value })}
                      />
                      <TextField
                        fullWidth
                        type="number"
                        name="group_count"
                        placeholder="Group Count"
                        onChange={(e) => setStageFormData({ ...stageFormData, group_count: e.target.value })}
                      />
                    </Stack>
                  </FormControl>
                  <ButtonGroup>
                    <Button sx={{ my: 2 }} variant="contained" onClick={createStage}>Create Stage</Button>
                    <Button sx={{ my: 2 }} variant="outlined" color="secondary" onClick={createStage}>Cancel</Button>
                  </ButtonGroup>
                </Box>
              }
            </Box>
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}


// In stage columns display name, id, stage_no
const getStageColumns = ({ navigate }) => {
  return [
    {
      field: '_id',
      headerName: 'Stage Id',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Stage Name',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'stage_no',
      headerName: 'Stage No',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    // Add a textfield and a button to send api request to create the rounds of the current stage. 
    {
      field: 'create_rounds',
      headerName: 'Create Rounds',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Button variant='contained' color="secondary" onClick={
            () => navigate('/stage/' + params.row._id)
          }>Manage</Button>
        </>
      )
    }
  ]
} 