import axios from 'axios';
import Endpoints from '../Endpoints';

export const getTournamentList = async (role) => {
    let endpoint = `/api/v1/tournament`;
    if (role === 'organization') endpoint = '/api/v1/tournament?organizerOnly=true';
    console.log("role")

    const { data } = await axios.get(endpoint);

    return data.data;
};

export const getTournament = async (id) => {
    const { data } = await axios.get(Endpoints.Tournament.GET_TOURNAMENT + id, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('USER_TOKEN')}`,
        },
    });
    return data;
};

export const createTournament = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Tournament.CREATE_TOURNAMENT, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('USER_TOKEN')}`,
        },
    });
    return result;
};

export const updateTournament = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Tournament.UPDATE_TOURNANET + id, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('USER_TOKEN')}`,
        },
    });
    return result;
};
