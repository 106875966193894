import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import Iconify from '../components/iconify';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GrFormView } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { useAppContext } from '../contexts/AppContext';
import { useState } from 'react';
import { getPostList } from '../services/Common/post.service';
import palette from '../theme/palette';
import { toast } from 'react-toastify';
import axios from 'axios';

// ------------------------------------------------------------------------

export default function PostPage() {
    const navigate = useNavigate()
    const { setLoading } = useAppContext();

    const deletePost = async (id) => {
        try {
            const res = await axios.delete("/api/v1/post/delete/" + id)
            toast.success("Post deleted successfully")
            window.location.reload();
        } catch (err) {
            toast.error("Some error occured")
        }
    }

    const columns = [
        {
            field: '_id',
            headerName: 'Post Id',
            minWidth: 220,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },

        {
            field: 'title',
            headerName: 'Title',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong >
                    <span >{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'user',
            headerName: 'User',
            minWidth: 200,
            valueGetter: (params) => params.row.user.name,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'description',
            headerName: 'Post Description',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'image',
            headerName: 'Image',
            minWidth: 270,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <img
                    className="w-full h-full p-1"
                    src={params.value}
                    alt=""
                />
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            flex: 1,
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Button color="secondary" onClick={() => deletePost(params.id)}>Delete</Button>
                </>
            ),
        },
    ];

    function getRowId(row) {
        return row._id; // Return the value of the `_id` property as the unique identifier
    }

    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 5,
    });

    const { result, setResult } = useApi(() => getPostList(), { onLoading: (status) => setLoading(status) });
    return (
        <>
            <Helmet>
                <title> Post | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Posts
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => { navigate(Page.POST_CREATE) }}
                        >
                            New Post
                        </Button>
                    </div>
                </Stack>

                <div style={{ height: '80vh', minWidth: '100%' }}>
                    {result && (result.length > 0 ?
                        <DataGrid
                            rows={result}
                            rowCount={result.length ?? 0}
                            columns={columns}
                            pageSizeOptions={[5, 10, 50]}
                            paginationModel={filter}
                            onPaginationModelChange={setFilter}
                            getRowId={getRowId}
                            rowHeight={150}
                        /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>
                    )}
                </div>
            </Container>
        </>
    );
}

