import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { toast } from 'react-toastify';
import { createTeam, getTeam, updateTeam } from '../../services/Common/team.service'
export default function TeamForm({ edit }) {

    const navigate = useNavigate();
    const { id } = useParams();

    const validationSchema = yup.object({
    });

    const formik = useFormik({
        initialValues: {
            team_name: '',
            category: '',
            image: null,
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData()
            formData.append('team_name', values.team_name)
            formData.append('category', values.category)
            formData.append('image', values.image)
            try {
                const res = edit ? await updateTeam(id, formData) : await createTeam(formData);
                navigate(Page.TEAM);
            } catch (error) {
                toast('Some Error occured', { type: 'error' })
            }
        },
    });

    useEffect(() => {
        if (id) {
            const TeamDetail = async () => {
                const res = await getTeam(id);
                // console.log(res.data)
                formik.setValues(res.data);
            };
            TeamDetail();
        }
    }, [id]);



    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>

                    <TextField
                        name="team_name"
                        label="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.team_name}
                        error={formik.touched.team_name && Boolean(formik.errors.team_name)}
                        helperText={formik.touched.team_name && formik.errors.team_name}
                        variant="filled"
                        size="small"
                        required
                    />

                    <TextField
                        name="category"
                        label="Category"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.category}
                        error={formik.touched.category && Boolean(formik.errors.category)}
                        helperText={formik.touched.category && formik.errors.category}
                        variant="filled"
                        size="small"
                        required
                    />

                    {/* Image field commented for now */}
                    <TextField
                        name="image"
                        label="Image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        size="small"
                        onChange={(event) => formik.setFieldValue('image', event.target.files[0])}
                        required={edit ? false : true}
                    />



                    <Stack direction="row" gap={2}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            Submit
                        </LoadingButton>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                navigate(Page.TEAM);
                            }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Stack>

            </form>
        </>
    );
}
