const Endpoints = {
    Auth: {
        LOGIN: "/api/v1/auth/login"
    },
    User: {
        GET_USER_LIST: "/api/v1/user",
        GET_USER: "/api/v1/user/",
        UPDATE_USER: "/api/v1/user/edit/"
    },
    Tournament: {
        GET_TOURNAMENT_LIST: "/api/v1/tournament",
        GET_ORGANIZER_TOURNAMENTS: "/api/v1/tournament?organizerOnly=true",
        GET_TOURNAMENT: "/api/v1/tournament/",
        CREATE_TOURNAMENT: "/api/v1/tournament/create",
        UPDATE_TOURNANET: "/api/v1/tournament/update/",
        // DELETE_TORUNAMENT: "/api/v1/user/"
    },
    Category: {
        GET_CATEGORY_LIST: "/api/v1/category",
        GET_CATEGORY: "/api/v1/category/",
        CREATE_CATEGORY: "/api/v1/category/create",
        UPDATE_CATEGORY: "/api/v1/category/update/",
        DELETE_CATEGORY: "/api/v1/category/delete/"
    },
    Games: {
        GET_GAMES_LIST: "/api/v1/game",
        GET_GAMES: "/api/v1/game/",
        CREATE_GAMES: "/api/v1/game/create",
        UPDATE_GAMES: "/api/v1/game/update/",
        DELETE_GAMES: "/api/v1/game/delete/"
    },
    Player: {
        GET_PLAYER_LIST: "/api/v1/player",
        GET_PLAYER: "/api/v1/player/",
        CREATE_PLAYER: "/api/v1/player/create",
        UPDATE_PLAYER: "/api/v1/player/update/",
        DELETE_PLAYER: "/api/v1/player/"
    },
    Team: {
        GET_TEAM_LIST: "/api/v1/team",
        GET_TEAM: "/api/v1/team/",
        CREATE_TEAM: "/api/v1/team/create",
        UPDATE_TEAM: "/api/v1/team/update/",
        DELETE_TEAM: "/api/v1/team/"
    },
    Post: {
        GET_POST_LIST: "/api/v1/post",
        GET_POST: "/api/v1/post/",
        CREATE_POST: "/api/v1/post/create",
        UPDATE_POST: "/api/v1/post/update/"
    },
    Comment: {
        GET_COMMENT_LIST: "/api/v1/comment",
        GET_COMMENT: "/api/v1/comment/",
        UPDATE_COMMENT: "/api/v1/comment/update/"
    },
    Banner: {
        GET_BANNER_LIST: "/api/v1/banner",
        GET_BANNER: "/api/v1/banner/",
        CREATE_BANNER: "/api/v1/banner/create",
        UPDATE_BANNER: "/api/v1/banner/update/"
    }
}
export default Object.freeze(Endpoints)