import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { MenuItem, Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../data/Page';
import { getBanner, updateBanner, createBanner } from '../../services/Common/banner.service';
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts/AppContext';


export default function BannerForm({ edit }) {

    const { setLoading } = useAppContext();

    const navigate = useNavigate();
    const { id } = useParams();

    const validationSchema = yup.object({
        type: yup.string().required('Type is required'),
        url: yup.string().required('Url is required'),
        status: yup.string().required('Status is required'),
    });

    const formik = useFormik({
        initialValues: {
            type: '',
            url: '',
            status: '',
            image: null
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('type', values.type);
            formData.append('url', values.url);
            formData.append('status', values.status);
            formData.append('image', values.image);
            // console.log(formData)
            if (setLoading) {
                setLoading(true);
            }
            try {
                edit ? await updateBanner(id, formData) : await createBanner(formData);
                toast(`Banner ${!edit ? 'Created' : 'Updated'}`, { type: 'success' });
                navigate(Page.BANNER);
            } catch (error) {
                toast('Some Error occured', { type: 'error' })
            }
            finally {
                if (setLoading) {
                    setLoading(false);
                }
            }
        },
    });

    useEffect(() => {
        if (id) {
            const BannerDetail = async () => {
                const res = await getBanner(id);
                formik.setValues(res.data[0]);
            };
            BannerDetail();
        }
    }, [id]);



    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>

                    <TextField
                        name="type"
                        label="Banner Type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.touched.type && formik.errors.type}
                        variant="filled"
                        size="small"
                        required
                    />

                    <TextField
                        name="url"
                        type="url"
                        label="Banner Url"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.url}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        helperText={formik.touched.url && formik.errors.url}
                        variant="filled"
                        size="small"
                        required
                    />
                    <TextField
                        name="status"
                        label="Banner Status"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.status}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        variant="filled"
                        size="small"
                        required
                    />
                    {/* Image field commented for now */}
                    <TextField
                        name="image"
                        label="Upload Image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        size="small"
                        onChange={(event) => formik.setFieldValue('image', event.target.files[0])}
                        required={edit ? false : true}

                    />



                    <Stack direction="row" gap={2}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            Submit
                        </LoadingButton>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                navigate(Page.BANNER);
                            }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Stack>

            </form>
        </>
    );
}
