import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
// sections
import Page from '../data/Page';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { useAppContext } from '../contexts/AppContext';
import { getPlayerList } from '../services/Common/player.service';
import { useState } from 'react';
import palette from '../theme/palette';
import { toast } from 'react-toastify';

// ------------------------------------------------------------------------

export default function PlayerPage() {

    const navigate = useNavigate()
    const { setLoading } = useAppContext();

    const columns = [
        {
            field: '_id',
            headerName: 'Player Id',
            minWidth: 300,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'ingame_id',
            headerName: 'Ingame Id',
            minWidth: 300,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            field: 'ingame_name',
            headerName: 'Ingame Name',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
        },
        {
            headerName: 'Action',
            field: 'actions',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => (
                <strong>
                    <span>{params.colDef.headerName}</span>
                </strong>
            ),
            renderCell: (params) => (
                <>
                    <Button
                        onClick={async () => {
                            if (setLoading) {
                                setLoading(true)
                            }
                            try {
                                // await deleteCategory(params.id)
                                setResult(prevPlayers => prevPlayers.filter(player => player._id !== params.id));
                                toast('Category Deleted', { type: "success" })
                            } catch (error) {
                                toast('Some Error Occured', { type: "error" })
                            }
                            finally {
                                if (setLoading) {
                                    setLoading(false);
                                }
                            }
                        }}>
                        <RiDeleteBinLine color='red' size={20} />
                    </Button>
                    <Button onClick={() => { navigate(`${Page.PLAYER_UPDATE}${params.id}`) }}>
                        <BiEdit color='black' size={20} />
                    </Button>
                </>
            ),
        },
    ];

    function getRowId(row) {
        return row._id; // Return the value of the `_id` property as the unique identifier
    }

    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 10,
    });


    const { result, setResult } = useApi(() => getPlayerList(), { onLoading: (status) => setLoading(status) });
    return (
        <>
            <Helmet>
                <title> Player | Trooper </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Players
                    </Typography>

                    <div className="flex gap-4 items-center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder='Search...'
                        />
                        {/* <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => { navigate(Page.PLAYER_CREATE) }}
                        >
                            New Player
                        </Button> */}
                    </div>
                </Stack>

                <div style={{ height: '80vh', minWidth: '100%' }}>
                    {result && (result.length > 0 ?
                        <DataGrid
                            rows={result}
                            rowCount={result.length ?? 0}
                            columns={columns}
                            pageSizeOptions={[5, 10, 50]}
                            paginationModel={filter}
                            onPaginationModelChange={setFilter}
                            getRowId={getRowId}
                        /> : <Box sx={{ textAlign: 'center', color: palette.grey[600], fontSize: '18px' }} >No Row Available</Box>)}
                </div>
            </Container>
        </>
    );
}
