import store from "../index.js"
import { toast } from 'react-toastify'
import { authActions } from "../reducers/auth"
import axios from "axios";
const { dispatch } = store;

export const login = async (email, password) => {
  dispatch(authActions.getUserStart())
  try {
    let payload = { email, password }
    const { data } = await axios.post('/api/v1/auth/login', payload)

    let user = data.data.userdata;
    let token = data.data.token;

    if (!(user.role == 'admin' || user.role == 'organization'))
      return toast('Access Denied', { type: "error" })

    localStorage.setItem('username', user.name)
    localStorage.setItem("USER_TOKEN", token)
    localStorage.setItem('email', user.email)
    localStorage.setItem("role", user.role)

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    if (user.role == 'organization')
      axios.defaults.headers.common['organizerOnly'] = "true"

    dispatch(authActions.getUserSuccess({ user, token }))
  }
  catch (error) {
    console.log(error)
    toast(error.message, { type: "error" })
    dispatch(authActions.getUserFail(error.message))
  }
}

export const logout = () => {
  dispatch(authActions.logOut())

  localStorage.removeItem('USER_TOKEN')
  localStorage.removeItem('user')
  localStorage.removeItem('username')
  localStorage.removeItem('role')
  localStorage.removeItem('email')

  toast('Logged out Successfully', { type: "success" })
}

export const loadPersistantUser = async () => {
  dispatch(authActions.getUserStart())

  try {
    const token = localStorage.getItem("USER_TOKEN");
    if (!token) return;

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const res = await axios.get("/api/v1/auth/me");
    const user = res.data.user;

    localStorage.setItem('username', user.name)
    localStorage.setItem("USER_TOKEN", token)
    localStorage.setItem('email', user.email)
    localStorage.setItem("role", user.role)

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    if (user.role == 'organization')
      axios.defaults.headers.common['organizerOnly'] = "true"


    dispatch(authActions.getUserSuccess({ user, token }))
  }
  catch (error) {
    console.log(error)
    toast(error.message, { type: "error" })
    dispatch(authActions.getUserFail(error.message))
  }
}