// component
import {
  MdApps,
  MdCategory,
  MdChat,
  MdCheckCircle,
  MdDashboard,
  MdFolder,
  MdGames,
  MdGroups,
  MdOutlineEditNote,
  MdOutlineGroup,
  MdPostAdd,
  MdReviews,
} from 'react-icons/md';
import { FaTrophy } from 'react-icons/fa';
import { BiLogOut, BiNews, BiTransfer, BiCommentDetail } from 'react-icons/bi';
import { CiStreamOn } from 'react-icons/ci';
import { CgDisplaySpacing } from 'react-icons/cg';
import { AiOutlineTeam } from 'react-icons/ai';
import { GiTabletopPlayers } from 'react-icons/gi';

import Page from '../../../data/Page'

let isAdmin = localStorage.getItem("role") === "admin";
console.log(isAdmin)

let adminTabs = [{
  title: 'Dashboard',
  path: Page.DASHBOARD,
  icon: <MdDashboard size={20} />,
},
{
  title: 'Users',
  path: `${Page.USER}`,
  icon: <MdGroups size={20} />,
},
{
  title: 'Category',
  path: Page.CATEGORY,
  icon: <MdCategory size={20} />,
},
{
  title: 'Games',
  path: `${Page.GAME}`,
  icon: <MdGames size={20} />,

},
{
  title: 'Teams',
  path: `${Page.TEAM}`,
  icon: <AiOutlineTeam size={20} />,

},
{
  title: 'Players',
  path: `${Page.PLAYER}`,
  icon: <GiTabletopPlayers size={20} />,

},
{
  title: 'Post',
  path: `${Page.POST}`,
  icon: <MdPostAdd size={20} />,

},
{
  title: 'Banner',
  path: `${Page.BANNER}`,
  icon: <CgDisplaySpacing size={20} />,
},
{
  title: 'Live Feed',
  path: `${Page.FEED}`,
  icon: <CgDisplaySpacing size={20} />,
},
{
  title: 'Section',
  path: `${Page.SECTION}`,
  icon: <CgDisplaySpacing size={20} />,
}, {
  title: 'Withdrawal',
  path: `${Page.WITHDRAWAL}`,
  icon: <BiTransfer size={20} />,
},
{
  title: 'Offers',
  path: `${Page.OFFERS}`,
  icon: <BiTransfer size={20} />,
},
{
  title: 'Notification',
  path: `${Page.NOTIFICATION}`,
  icon: <BiCommentDetail size={20} />,
}
]

let navConfig = [
  {
    title: 'Tournaments',
    path: `${Page.TOURNAMENT}`,
    icon: <FaTrophy size={20} />,
  },
];

if (isAdmin) {
  navConfig = [...navConfig, ...adminTabs]
}

console.log(navConfig)

export default navConfig;
