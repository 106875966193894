import axios from "axios";
import Endpoints from "../Endpoints";

export const getUserList = async () => {
    const { data } = await axios.get(Endpoints.User.GET_USER_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data.data;
};

export const getUser = async (id) => {
    const { data } = await axios.get(Endpoints.User.GET_USER + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const updateUser = async (id, data) => {
    const { data: { result }, } = await axios.post(Endpoints.User.UPDATE_USER + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};
