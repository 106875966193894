import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";

const store = configureStore({
  reducer: {
    auth: authReducer
  },
  middleware: [...getDefaultMiddleware()]
});

export default store;