import axios from "axios";
import Endpoints from "../Endpoints";

export const getCommentList = async () => {
    const { data } = await axios.get(Endpoints.Comment.GET_COMMENT_LIST, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}` }
    });
    return data.data;
};

export const getComment = async (id) => {
    const { data } = await axios.get(Endpoints.Comment.GET_COMMENT + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const updateComment = async (id, data) => {
    const { data: { result }, } = await axios.put(Endpoints.Comment.UPDATE_COMMENT + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};
