import axios from "axios";
import Endpoints from "../Endpoints";

export const getPostList = async () => {
    const { data } = await axios.get(Endpoints.Post.GET_POST_LIST, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data.data;
};

export const getPost = async (id) => {
    const { data } = await axios.get(Endpoints.Post.GET_POST + id, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return data
}

export const createPost = async (data) => {
    const {
        data: { result },
    } = await axios.post(Endpoints.Post.CREATE_POST, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};

export const updatePost = async (id, data) => {
    const {
        data: { result },
    } = await axios.put(Endpoints.Post.UPDATE_POST + id, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
        }
    });
    return result;
};