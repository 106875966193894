import { Typography, TextField, Stack, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import RoundTeamList from "../components/RoundTeamList";


export default function ManageRound() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [round, setRound] = useState({
    room_id: "",
    room_password: "",
    map_name: "",
    room_name: "",
    teams: [],
  })
  const { isAuthenticated } = useSelector(state => state.auth)

  useEffect(() => {
    const getRoundData = async () => {
      const { data } = await axios.get(`api/v1/stage/round/${id}`)
      const roundData = data.round;
      setRound(roundData)
      setLoading(false)
    };
    if (isAuthenticated)
      getRoundData();
  }, [isAuthenticated])

  const onChange = (e) => {
    e.preventDefault();
    setRound({
      ...round,
      [e.target.name]: e.target.value
    },
    )
  }

  const { room_id, room_name, room_password, map_name } = round

  const updateRound = async () => {
    try {
      const res = await axios.put(`api/v1/stage/round/${id}`, { room_id, room_name, room_password, map_name })
      setRound(res.data.round)
      navigate(`/stage/${round.stage}`)
    } catch (err) {
      console.log(err)
    }
  }

  if (loading) return <>Loading</>;
  return <>
    <Stack spacing={3}>
      <Typography variant="h4" sx={{ mb: 2 }}>Manage Round</Typography>
      <TextField
        value={map_name}
        name="map_name"
        onChange={onChange}
      />
      <TextField
        value={room_name}
        name="room_name"
        onChange={onChange}
      />
      <TextField
        value={room_id}
        name="room_id"
        onChange={onChange}
      />
      <TextField
        value={room_password}
        name="room_password"
        onChange={onChange} />
      <Button onClick={updateRound}>Update Round</Button>
    </Stack>
    <RoundTeamList teams={round.teams} />
  </>
}